<template>
  <div>
    <div class="mid fixed w-90 w-80-l h-100" style="border-right:0;background-color:#f5f6fa">
      <div class="w-70 center pv5">
        <div class="error pb3" v-if="error">{{ error }}</div>

        <div class="pa2 fr"><span v-show="showmonth">This month</span><a href="#" class="fade" v-show="!showmonth" @click.prevent="showmonth = true">This Month</a> <span v-show="!showmonth" class="ml2 dib">All time</span><a href="#" class="ml2 fade" v-show="showmonth" @click.prevent="showmonth = false">All time</a></div>
        <div class="pa2 b">&nbsp;</div>
        <div>
          <div class="fl w-25 tc pa2">
            <div class="box ph3 pt3 pb4">
              <h2 class="mb2">{{ loading ? 0 : showmonth ? data.mails_tm : data.mails }}</h2>
              <div>Transactions</div>
            </div>
          </div>
          <div class="fl w-25 tc pa2">
            <div class="box ph3 pt3 pb4">
              <h2 class="mb2">{{ loading ? '...' : showmonth ? data.opens_tm : data.opens }}</h2>
              <div>Debtors</div>
            </div>
          </div>
          <div class="fl w-25 tc pa2">
            <div class="box ph3 pt3 pb4">
              <h2 class="mb2">{{ loading ? '...' : showmonth ? data.clicks_tm : data.clicks }}</h2>
              <div>Creditors</div>
            </div>
          </div>
          <div class="fl w-25 tc pa2">
            <div class="box ph3 pt3 pb4">
              <h2 class="mb2">{{ loading ? '...' : showmonth ? data.fails_tm : data.fails }}</h2>
              <div>Fails</div>
            </div>
          </div>
          <div class="cb"></div>
        </div>
        <div class="mt3">
          <div class="pa2 b">Account</div>
          <div class="fl w-25 tc pa2">
            <div class="box ph3 pt3 pb4">
              <h2 class="mb2">-</h2>
              <div class="truncate">-</div>
            </div>
          </div>
          <div class="fl w-25 tc pa2">
            <div class="box ph3 pt3 pb4">
              <h2 class="mb2">{{ loading ? '...' : data.automations }}</h2>
              <div>-</div>
            </div>
          </div>
          <div class="fl w-25 tc pa2">
            <div class="box ph3 pt3 pb4">
              <h2 class="mb2">{{ loading ? '...' : data.customers }}</h2>
              <div>Customers</div>
            </div>
          </div>
          <div class="fl w-25 tc pa2">
            <div class="box ph3 pt3 pb4">
              <h2 class="mb2">{{ loading ? '...' : data.team_active }}<span class="fadest">/{{ loading ? '...' : data.team_all }}</span></h2>
              <div class="truncate">Team members</div>
            </div>
          </div>
          <div class="cb"></div>
        </div>
        <div class="mt3">
          <div class="fl w-50 pa2">
            <div class="box pa3">
              <h4 class="mt0 mb2">-</h4>
            </div>
          </div>
          <div class="fl w-50 pa2">
            <div class="box pa3">
              <h4 class="mt0 mb2">-</h4>
            </div>
          </div>
          <div class="cb"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      error: '',
      loading: true,
      showmonth: true,
      data: {
        mails: 0,
        opens: 0,
        clicks: 0,
        fails: 0,
        mails_tm: 0,
        opens_tm: 0,
        clicks_tm: 0,
        fails_tm: 0,
        events: 0,
        automations: 0,
        customers: 0,
        team_active: 0,
        team_all: 0,
        recent_emails: [],
        recent_customers: []
      }
    }
  },
  mounted: async function() {
    this.loading = true
    try {
      const response = await fetch('/api/org/stats', {
        headers: { 'Content-Type': 'application/json;charset=utf-8' }
      })
      this.loading = false
      let data = {}
      try {
        data = await response.json()
      } catch (j) {}
      if (response.status !== 200) {
        this.error = data.error ? data.error : 'There has been a connection error. Try again later.'
        return
      }
      this.data = data
    } catch (j) {
      // #todo error
      this.loading = false
    }
  }
}
</script>

<style scoped>
</style>