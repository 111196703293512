<template>
  <div>
    <div class="mid fixed w-90 w-80-l h-100" style="border-right: 0; background-color: #f5f6fa">
      <div class="w-90 center pv5">
        <div class="error pb3" v-if="error">{{ error }}</div>
        <div class="pa2 fr">
          <span v-show="showmonth">This month</span
          ><a href="#" class="fade" v-show="!showmonth" @click.prevent="showmonth = true">This Month</a>
          <span v-show="!showmonth" class="ml2 dib">All time</span
          ><a href="#" class="ml2 fade" v-show="showmonth" @click.prevent="showmonth = false">All time</a>
        </div>
        <div class="pa2 b">&nbsp;</div>
        <div>
          <div class="fl w-third tc pa2">
            <div class="box ph3 pt3 pb4">
              <h2 class="mb2">{{ loading ? '...' : 0 }}</h2>
              <div>Sales</div>
            </div>
          </div>
          <div class="fl w-third tc pa2">
            <div class="box ph3 pt3 pb4">
              <h2 class="mb2">{{ loading ? '...' : 0 }}</h2>
              <div>Supplies</div>
            </div>
          </div>
          <div class="fl w-third tc pa2">
            <div class="box ph3 pt3 pb4">
              <h2 class="mb2">{{ loading ? '...' : 0 }}</h2>
              <div>Investment</div>
            </div>
          </div>
          <div class="cb"></div>
        </div>
        <div>
          <div class="fl w-50 tc pa2">
            <div class="box ph3 pt3 pb4">
              <h2 class="mb2">{{ loading ? '...' : 0 }}</h2>
              <div>Debt</div>
            </div>
          </div>
          <div class="fl w-50 tc pa2">
            <div class="box ph3 pt3 pb4">
              <h2 class="mb2">{{ loading ? '...' : 0 }}</h2>
              <div>Credit</div>
            </div>
          </div>
          <div class="cb"></div>
        </div>

        <!-- sales and purchasess -->
        <h3>Sales/Purchases</h3>
        <table class="mt4 w-100">
          <thead>
            <tr>
              <th>Date</th>
              <th>Type</th>
              <th>Product</th>
              <th>Invoice/Ref number</th>
              <th>Quantity</th>
              <th>Unit Cost/Price</th>
              <th>Total Cost/Price</th>
            </tr>
          </thead>
          <tr v-for="transaction of transactions.data" :key="transaction._id">
            <td>{{ new Date(transaction.date).toUTCString() }}</td>
            <td>
              <div v-if="transaction.type === 'settlement'">Debt repayment</div>
              <div v-else>{{ transaction.subtype ? transaction.subtype : transaction.type }}</div>
            </td>
            <td>
              <router-link :to="'/inventory/' + transaction.product">{{ transaction.product_title }}</router-link>
            </td>
            <td>{{ transaction.reference }}</td>
            <td>{{ transaction.quantity }}</td>
            <td>{{ transaction.unit_price ? transaction.unit_price.toLocaleString() : '0' }}</td>
            <td>{{ transaction.amount ? transaction.amount.toLocaleString() : '0' }}</td>
          </tr>
        </table>

        <!-- payables/receivables -->
        <h3>Payables/Receivables</h3>
        <table class="mt4 w-100">
          <thead>
            <tr>
              <th>Date</th>
              <th>Type</th>
              <th>Invoice/Ref number</th>
              <th>Description/Product</th>
              <th>Total Amount</th>
              <!-- <th>Balance due</th> -->
              <th>Due date</th>
            </tr>
          </thead>
          <tr v-for="transaction of debts.data" :key="transaction._id">
            <td>{{ new Date(transaction.date).toUTCString() }}</td>
            <td>
              <div v-if="transaction.type === 'settlement'">Debt repayment</div>
              <div v-else>{{ transaction.subtype ? transaction.subtype : transaction.type }}</div>
            </td>
            <td>{{ transaction.reference }}</td>
            <td>
              <router-link :to="'/inventory/' + transaction.product" v-if="transaction.product">{{
                transaction.product_title
              }}</router-link
              ><span v-else>{{ transaction.description }}</span>
            </td>
            <td>{{ transaction.amount ? transaction.amount.toLocaleString() : 0 }}</td>
            <td>{{ new Date(transaction.due_date).toUTCString() }}</td>
          </tr>
        </table>

        <!-- investment -->
        <h3>Others</h3>
        <table class="mt4 w-100">
          <thead>
            <tr>
              <th>Date</th>
              <th>Type</th>
              <th>Invoice/Ref number</th>
              <th>Description</th>
              <th>Amount</th>
            </tr>
          </thead>
          <tr v-for="transaction of others.data" :key="transaction._id">
            <td>{{ new Date(transaction.date).toUTCString() }}</td>
            <td>{{ transaction.subtype }}</td>
            <td>{{ transaction.reference }}</td>
            <td>{{ transaction.description }}</td>
            <td>{{ transaction.amount ? transaction.amount.toLocaleString() : 0 }}</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      error: '',
      showmonth: false,
      transactions: {
        data: [],
      },
      debts: {
        data: [],
      },
      others: {
        data: [],
      },
      loading: true,
    }
  },
  mounted: async function () {
    try {
      this.error = ''
      let response = await fetch('/api/entities/' + this.$route.params.id + '/transactions', {
        headers: { 'Content-Type': 'application/json;charset=utf-8' },
      })
      let data = {}
      try {
        data = await response.json()
      } catch (j) {}
      if (response.status !== 200 || !data.data || !Array.isArray(data.data)) {
        this.error = data.error ? data.error : 'There has been a connection error. Try again later.'
        return
      }
      this.transactions.data = data.data
      // Debts
      response = await fetch('/api/entities/' + this.$route.params.id + '/debts', {
        headers: { 'Content-Type': 'application/json;charset=utf-8' },
      })
      data = {}
      try {
        data = await response.json()
      } catch (j) {}
      if (response.status !== 200 || !data.data || !Array.isArray(data.data)) {
        this.error = data.error ? data.error : 'There has been a connection error pulling debts. Try again later.'
        return
      }
      this.debts.data = data.data
      // Debts
      response = await fetch('/api/entities/' + this.$route.params.id + '/others', {
        headers: { 'Content-Type': 'application/json;charset=utf-8' },
      })
      data = {}
      try {
        data = await response.json()
      } catch (j) {}
      if (response.status !== 200 || !data.data || !Array.isArray(data.data)) {
        this.error = data.error ? data.error : 'There has been a connection error pulling debts. Try again later.'
        return
      }
      this.others.data = data.data
    } catch (e) {
      this.error = e.message
    }
  },
}
</script>

<style scoped></style>
