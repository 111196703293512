var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", [
      _c("h3", { staticClass: "asc ma0 pb2" }, [_vm._v("Current")]),
      _vm._v(" "),
      _c("div", { staticClass: "pv2" }, [
        _c(
          "div",
          { staticClass: "flex", staticStyle: { "max-width": "400px" } },
          [
            _c(
              "div",
              {
                staticClass: "w-50 tc",
                staticStyle: { "border-right": "1px solid #f5f4fa" },
              },
              [
                _vm.org.billing && _vm.org.billing.pro
                  ? _c("h1", [_vm._v("$" + _vm._s(_vm.currentCharge()))])
                  : _c("div", [
                      _c("h1", { staticClass: "dib" }, [_vm._v("$0")]),
                      _vm._v(" (free plan)"),
                    ]),
                _vm._v(" "),
                _c("div", { staticClass: "fade" }, [
                  _vm._v(_vm._s(_vm.org.sent) + " sent emails"),
                ]),
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "w-50 pl4 tc" }, [
              _vm.org.billing && _vm.org.billing.pro
                ? _c("div", [
                    _c(
                      "button",
                      {
                        staticClass: "button-del",
                        on: { click: _vm.confirmCancel },
                      },
                      [_vm._v("Cancel subscription")]
                    ),
                  ])
                : _c("div", [
                    _c(
                      "button",
                      {
                        staticClass: "button-default",
                        attrs: { disabled: _vm.upgrading },
                        on: { click: _vm.upgrade },
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.upgrading ? "Upgrading..." : "Upgrade to Pro"
                          )
                        ),
                      ]
                    ),
                  ]),
              _vm._v(" "),
              _c("a", { attrs: { href: "/pricing", target: "_blank" } }, [
                _vm._v("View plans"),
              ]),
            ]),
          ]
        ),
      ]),
    ]),
    _vm._v(" "),
    _vm.info
      ? _c("div", { staticClass: "info pt3" }, [_vm._v(_vm._s(_vm.info))])
      : _vm._e(),
    _vm._v(" "),
    _vm.error
      ? _c("div", { staticClass: "error pt3" }, [_vm._v(_vm._s(_vm.error))])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "mt4" },
      [
        _c("h3", { staticClass: "asc ma0 pb2" }, [_vm._v("Cards")]),
        _vm._v(" "),
        _vm._l(_vm.org.cards, function (card) {
          return _c(
            "div",
            {
              key: card.id,
              class: [
                "list-item",
                _vm.deleting.indexOf(card.id) > -1 ? "deleting" : "",
              ],
            },
            [
              _c("div", { staticClass: "fr pt3 icon" }, [
                !card.default && !_vm.settingDefault
                  ? _c(
                      "a",
                      {
                        attrs: { href: "#" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.makeDefault(card.id)
                          },
                        },
                      },
                      [
                        _c("check-bold-icon", {
                          attrs: {
                            title: "Make default",
                            "data-tooltip": "Make default",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    attrs: { href: "" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.confirmDelete(card.id)
                      },
                    },
                  },
                  [
                    _c("credit-card-remove-icon", {
                      attrs: {
                        title: "Remove card",
                        "data-tooltip": "Remove card",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "bb-line pv3" }, [
                _vm._v(_vm._s(card.type) + " "),
                _c("span", { staticClass: "fade" }, [
                  _vm._v("**** " + _vm._s(card.last4)),
                ]),
                _vm._v(" "),
                card.default
                  ? _c("small", { staticClass: "tag tag-alt" }, [
                      _vm._v("default"),
                    ])
                  : _vm._e(),
              ]),
            ]
          )
        }),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.cardready,
                expression: "cardready",
              },
            ],
            staticClass: "bb-line pv3 fade",
          },
          [
            _c(
              "a",
              {
                attrs: { href: "" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    _vm.modalOn = true
                  },
                },
              },
              [_vm._v("Add payment card")]
            ),
          ]
        ),
      ],
      2
    ),
    _vm._v(" "),
    _vm._m(0),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.modalOn,
            expression: "modalOn",
          },
        ],
        staticClass: "modal",
        attrs: { tabindex: "0" },
        on: {
          keyup: function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
            ) {
              return null
            }
            return _vm.closeModal.apply(null, arguments)
          },
        },
      },
      [
        _c(
          "div",
          {
            staticClass: "center modal-body mt6 pa4",
            staticStyle: { width: "500px" },
          },
          [
            _c(
              "form",
              {
                attrs: { method: "post" },
                on: {
                  submit: function ($event) {
                    $event.preventDefault()
                    return _vm.addCard.apply(null, arguments)
                  },
                },
              },
              [
                _c("h3", { staticClass: "ma0 pa0 asc" }, [_vm._v("Add Card")]),
                _vm._v(" "),
                _vm.cardError
                  ? _c("div", { staticClass: "pt2 error" }, [
                      _vm._v(_vm._s(_vm.cardError)),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { ref: "card", staticClass: "pa3 mv3 card" }),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticStyle: { "margin-bottom": "0" },
                    attrs: { type: "submit", disabled: _vm.addingCard },
                  },
                  [_vm._v(_vm._s(_vm.addingCard ? "Adding..." : "Add"))]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "button-plain",
                    staticStyle: { "margin-bottom": "0" },
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.closeModal.apply(null, arguments)
                      },
                    },
                  },
                  [_vm._v("Cancel")]
                ),
              ]
            ),
          ]
        ),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.subModalOn,
            expression: "subModalOn",
          },
        ],
        staticClass: "modal",
        attrs: { tabindex: "0" },
        on: {
          keyup: function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
            ) {
              return null
            }
            return _vm.closeModal.apply(null, arguments)
          },
        },
      },
      [
        _c(
          "div",
          {
            staticClass: "center modal-body mt6 pa4",
            staticStyle: { width: "500px" },
          },
          [
            _c(
              "form",
              {
                attrs: { method: "post" },
                on: {
                  submit: function ($event) {
                    $event.preventDefault()
                    return _vm.cancelSub.apply(null, arguments)
                  },
                },
              },
              [
                _c("h3", { staticClass: "ma0 pa0 asc" }, [
                  _vm._v("Cancel Subscription"),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "pv3" }, [
                  _vm._v(
                    "Kindly confirm you want to cancel your subscription. Note that your account will reset to the free plan and you will be charged "
                  ),
                  _c("strong", [_vm._v("$" + _vm._s(_vm.currentCharge()))]),
                  _vm._v(" for current usage."),
                ]),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticStyle: { "margin-bottom": "0" },
                    attrs: { type: "submit", disabled: _vm.cancelingSub },
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.cancelingSub
                          ? "Canceling..."
                          : "Cancel Subscription"
                      )
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "button-plain",
                    staticStyle: { "margin-bottom": "0" },
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.closeModal.apply(null, arguments)
                      },
                    },
                  },
                  [_vm._v("Cancel")]
                ),
              ]
            ),
          ]
        ),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.delModalOn,
            expression: "delModalOn",
          },
        ],
        staticClass: "modal",
        attrs: { tabindex: "0" },
        on: {
          keyup: function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
            ) {
              return null
            }
            return _vm.closeModal.apply(null, arguments)
          },
        },
      },
      [
        _c(
          "div",
          {
            staticClass: "center modal-body mt6 pa4",
            staticStyle: { width: "500px" },
          },
          [
            _c(
              "form",
              {
                attrs: { method: "post" },
                on: {
                  submit: function ($event) {
                    $event.preventDefault()
                    return _vm.deleteCard.apply(null, arguments)
                  },
                },
              },
              [
                _c("h3", { staticClass: "ma0 pa0 asc" }, [
                  _vm._v("Delete Card"),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "pv3" }, [
                  _vm._v("Kindly confirm you want to delete the card."),
                ]),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticStyle: { "margin-bottom": "0" },
                    attrs: { type: "submit", disabled: _vm.deletingCard },
                  },
                  [
                    _vm._v(
                      _vm._s(_vm.deletingCard ? "Deleting..." : "Delete Card")
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "button-plain",
                    staticStyle: { "margin-bottom": "0" },
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.closeModal.apply(null, arguments)
                      },
                    },
                  },
                  [_vm._v("Cancel")]
                ),
              ]
            ),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "mt4" }, [
      _c("h3", { staticClass: "asc ma0 pb2" }, [_vm._v("Billing history")]),
      _vm._v(" "),
      _c("div", { staticClass: "list-item" }, [
        _c("div", { staticClass: "bb-line pv3 fade" }, [
          _vm._v("No billing history"),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }