<template>
  <div class="w-100">
    <div class="fixed w-20-l w-10 h-100 side">
      <header class="pv3 ph4 bb-line relative">
        <h3 class="pa0 ma0 truncate">
          <a href="#" @click.prevent="toggleSwitch" v-if="org.accounts && org.accounts.length"><chevron-down-icon class="mr2" v-show="!switchOn" /><chevron-up-icon class="mr2" v-show="switchOn" /> <span class="hdr-color">{{ org.name}}</span></a>
          <router-link to="/" class="hdr-color" v-else>{{ org.name}} </router-link>
        </h3>
        <div class="pt1" v-show="switchOn">
          <div class="f5 pv1 ma0" v-for="account in org.accounts" :key="account.id"><chevron-up-icon class="mr2" style="visibility:hidden" /> <a href="#" @click.prevent="switchAcc(account.id)">{{account.name}}</a></div>
        </div>
      </header>
      <div class="pv4-ns pr4 ph4-ns ph3-m ph3 mb4">
        <div class="mb3 dib-l dn">
          <router-link to="/transactions/new" class="b button button-default" style="display:inline-block;padding:10px 20px">Add a new transaction</router-link>
        </div>
        <ul class="nav">
          <li class="pt1 pb2 ph2"><router-link to="/" :class="$route.path === '/' ? 'active':''"><home-icon class="mr2" /> <span class="di-l dn">Dashboard</span></router-link></li>
          <li class="pt1 pb2 ph2"><router-link to="/transactions" active-class="active"><zap-icon class="mr2" /> <span class="di-l dn">Transactions</span></router-link></li>
          <li class="pt1 pb2 ph2"><router-link to="/pnr" active-class="active"><send-icon class="mr2" /> <span class="di-l dn">Payables/Receiveables</span></router-link></li>
          <li class="pt1 pb2 ph2"><router-link to="/entities" active-class="active"><user-plus-icon class="mr2" /> <span class="di-l dn">Entities</span></router-link></li>
          <li class="pt1 pb2 ph2"><router-link to="/inventory" active-class="active"><user-plus-icon class="mr2" /> <span class="di-l dn">Inventory</span></router-link></li>
          <li class="pt1 pb2 ph2"><router-link to="/pricing" active-class="active"><user-plus-icon class="mr2" /> <span class="di-l dn">Pricing</span></router-link></li>
          <li class="pt1 pb2 ph2"><router-link to="/invoicing" active-class="active"><user-plus-icon class="mr2" /> <span class="di-l dn">Invoicing</span></router-link></li>
          <li class="pt1 pb2 ph2"><router-link to="/invoicing" active-class="active"><user-plus-icon class="mr2" /> <span class="di-l dn">Insights</span></router-link></li>
          <li class="pt1 pb2 ph2"><router-link to="/reports" active-class="active"><user-plus-icon class="mr2" /> <span class="di-l dn">Reports</span></router-link></li>
        </ul>
        <div class="mt3"></div>
        <ul class="nav">
          <li class="pt2 pb2 ph2"><router-link to="/settings/profile" :class="$route.path.startsWith('/settings') ? 'active':''"><settings-icon class="mr2" /> <span class="di-l dn">Settings</span></router-link></li>
          <li class="pt2 pb2 ph2"><a href="/logout"><log-out-icon class="mr2" /> <span class="di-l dn">Logout</span></a></li>
        </ul>
      </div>
    </div>
    <router-view :org="org"></router-view>
  </div>
</template>

<script>
import { HomeIcon, ZapIcon, SendIcon, GitMergeIcon, UserPlusIcon, SettingsIcon, LogOutIcon, ChevronDownIcon, ChevronUpIcon, PlusIcon } from 'vue-feather-icons'

export default {

  components: {
    HomeIcon, ZapIcon, SendIcon, GitMergeIcon, UserPlusIcon, SettingsIcon, ChevronUpIcon, ChevronDownIcon, LogOutIcon, PlusIcon
  },
  data() {
    return {
      switchOn: false
    }
  },

  computed: {
    org () {
	    return this.$store.state.org
    }
  },
  mounted: async function () {
    const response = await fetch('/api/org', {
      headers: {
        'Content-Type': 'application/json;charset=utf-8'
      }
    })
    let data = {}
    try {
      data = await response.json()
    } catch (j) {}
    if (response.status === 401) {
      window.location.href = '/login'
      return
    }

    if (data.name) {
      // this.org = data
      this.$store.commit('setOrg', data)
    }
  },

  methods: {
    toggleSwitch() {
      this.switchOn = !this.switchOn
    },
    async switchAcc(id) {
      // Switch account
      document.querySelector('body').style.opacity = 0.5
      try {
        const response = await fetch(`/api/org/switch/${id}`, {
          headers: { 'Content-Type': 'application/json;charset=utf-8' }
        })
        const data = await response.json()
        if (response.status === 200) {
          window.location.href = '/app'
        }
      } catch (e) {
        console.log(e)
      }
      document.querySelector('body').style.opacity = 1
      this.switchOn = false
    }
  }
}
</script>

<style scoped>
</style>