var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "modal", class: _vm.productModal ? "" : "dn" }, [
      _c("div", { staticClass: "modal-body w-50 center mt5 pa4" }, [
        _c("h3", { staticClass: "asc ma0 pb2" }, [_vm._v("Add new product")]),
        _vm._v(" "),
        _c(
          "form",
          {
            attrs: { method: "post" },
            on: {
              submit: function ($event) {
                $event.preventDefault()
                return _vm.createProduct.apply(null, arguments)
              },
            },
          },
          [
            _c("div", [
              _c("div", { staticClass: "mv1 flex justify-between" }, [
                _c("div", { staticClass: "w-50 mr1" }, [
                  _c("label", { staticClass: "db fade pv1" }, [_vm._v("SKU")]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.new_product.sku,
                        expression: "new_product.sku",
                      },
                    ],
                    staticClass: "w-100",
                    attrs: { type: "text" },
                    domProps: { value: _vm.new_product.sku },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.new_product, "sku", $event.target.value)
                      },
                    },
                  }),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "w-50 ml1" }, [
                  _c("label", { staticClass: "db fade pv1" }, [_vm._v("Name")]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.new_product.name,
                        expression: "new_product.name",
                      },
                    ],
                    staticClass: "w-100",
                    attrs: { type: "text", required: "" },
                    domProps: { value: _vm.new_product.name },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.new_product, "name", $event.target.value)
                      },
                    },
                  }),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "mv1" }, [
                _c("label", { staticClass: "db fade pv1" }, [
                  _vm._v("Description"),
                ]),
                _vm._v(" "),
                _c("textarea", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.new_product.description,
                      expression: "new_product.description",
                    },
                  ],
                  staticClass: "w-100",
                  domProps: { value: _vm.new_product.description },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.new_product,
                        "description",
                        $event.target.value
                      )
                    },
                  },
                }),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "mv1 flex justify-between" }, [
                _c("div", { staticClass: "w-50 mr1" }, [
                  _c("label", { staticClass: "db fade pv1" }, [_vm._v("Size")]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.new_product.size,
                        expression: "new_product.size",
                      },
                    ],
                    staticClass: "w-100",
                    attrs: { type: "text" },
                    domProps: { value: _vm.new_product.size },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.new_product, "size", $event.target.value)
                      },
                    },
                  }),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "w-50 ml1" }, [
                  _c("label", { staticClass: "db fade pv1" }, [
                    _vm._v("Color"),
                  ]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.new_product.color,
                        expression: "new_product.color",
                      },
                    ],
                    staticClass: "w-100",
                    attrs: { type: "text" },
                    domProps: { value: _vm.new_product.color },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.new_product, "color", $event.target.value)
                      },
                    },
                  }),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "mt4 flex justify-between" }, [
              _c(
                "button",
                {
                  staticClass: "w-40",
                  attrs: { type: "submit", disabled: _vm.addingProduct },
                },
                [
                  _vm._v(
                    "\n            " +
                      _vm._s(
                        _vm.addingProduct
                          ? "Creating product..."
                          : "Create product"
                      ) +
                      "\n          "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "button-grey",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      _vm.productModal = false
                    },
                  },
                },
                [_vm._v("Close")]
              ),
            ]),
          ]
        ),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "mid fixed w-90 w-80-l h-100",
        staticStyle: { "border-right": "0", "background-color": "#f5f6fa" },
      },
      [
        _c("div", { staticClass: "w-50 center pv5" }, [
          _c("h3", { staticClass: "asc ma0 pv1" }, [
            _vm._v("Create new transaction"),
          ]),
          _vm._v(" "),
          _vm.info
            ? _c("div", { staticClass: "info pb3" }, [_vm._v(_vm._s(_vm.info))])
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "pv1 flex justify-between" }, [
            _c("div", { staticClass: "w-50 pr1" }, [
              _c("label", { staticClass: "db fade pv1" }, [_vm._v("Type")]),
              _vm._v(" "),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.type,
                      expression: "type",
                    },
                  ],
                  staticClass: "w-100",
                  on: {
                    change: function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.type = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    },
                  },
                },
                [
                  _c("option", { attrs: { value: "" } }, [_vm._v("-Select-")]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "sales" } }, [
                    _vm._v("Sales"),
                  ]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "purchases" } }, [
                    _vm._v("Purchases (COGS)"),
                  ]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "expenses" } }, [
                    _vm._v("Expenses"),
                  ]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "inflows" } }, [
                    _vm._v("Others - Inflows"),
                  ]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "outflows" } }, [
                    _vm._v("Others - Outflows"),
                  ]),
                ]
              ),
            ]),
            _vm._v(" "),
            _vm.type
              ? _c("div", { staticClass: "w-50 pl1" }, [
                  _vm.type == "expenses"
                    ? _c("div", [
                        _c("label", { staticClass: "db fade pv1" }, [
                          _vm._v("Expenses"),
                        ]),
                        _vm._v(" "),
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.subtype,
                                expression: "subtype",
                              },
                            ],
                            staticClass: "w-100",
                            on: {
                              change: function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.subtype = $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              },
                            },
                          },
                          [
                            _c("option", { attrs: { value: "" } }, [
                              _vm._v("-Category-"),
                            ]),
                            _vm._v(" "),
                            _c("option", { attrs: { value: "transport" } }, [
                              _vm._v("Transportation"),
                            ]),
                            _vm._v(" "),
                            _c("option", { attrs: { value: "commission" } }, [
                              _vm._v("Commission & Fees"),
                            ]),
                            _vm._v(" "),
                            _c("option", { attrs: { value: "bank" } }, [
                              _vm._v("Bank Charges"),
                            ]),
                            _vm._v(" "),
                            _c("option", { attrs: { value: "ads" } }, [
                              _vm._v("Advertisement"),
                            ]),
                            _vm._v(" "),
                            _c("option", { attrs: { value: "packaging" } }, [
                              _vm._v("Packaging"),
                            ]),
                            _vm._v(" "),
                            _c("option", { attrs: { value: "discount" } }, [
                              _vm._v("Discount Given"),
                            ]),
                            _vm._v(" "),
                            _c("option", { attrs: { value: "salary" } }, [
                              _vm._v("Salary"),
                            ]),
                            _vm._v(" "),
                            _c("option", { attrs: { value: "fees" } }, [
                              _vm._v("Fees"),
                            ]),
                            _vm._v(" "),
                            _c("option", { attrs: { value: "rent" } }, [
                              _vm._v("Rent"),
                            ]),
                            _vm._v(" "),
                            _c("option", { attrs: { value: "assets" } }, [
                              _vm._v("Assets Acquisition"),
                            ]),
                            _vm._v(" "),
                            _c("option", { attrs: { value: "utility" } }, [
                              _vm._v("Utility"),
                            ]),
                            _vm._v(" "),
                            _c(
                              "option",
                              { attrs: { value: "interest_expense" } },
                              [_vm._v("Interest Expense")]
                            ),
                            _vm._v(" "),
                            _c("option", { attrs: { value: "other" } }, [
                              _vm._v("Add more"),
                            ]),
                          ]
                        ),
                      ])
                    : _vm.type == "inflows"
                    ? _c("div", [
                        _c("label", { staticClass: "db fade pv1" }, [
                          _vm._v("Others - Inflow"),
                        ]),
                        _vm._v(" "),
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.subtype,
                                expression: "subtype",
                              },
                            ],
                            staticClass: "w-100",
                            on: {
                              change: function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.subtype = $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              },
                            },
                          },
                          [
                            _c("option", { attrs: { value: "" } }, [
                              _vm._v("-Category-"),
                            ]),
                            _vm._v(" "),
                            _c("option", { attrs: { value: "capital" } }, [
                              _vm._v("Capital"),
                            ]),
                            _vm._v(" "),
                            _c("option", { attrs: { value: "borrowings" } }, [
                              _vm._v("Borrowings"),
                            ]),
                            _vm._v(" "),
                            _c("option", { attrs: { value: "service" } }, [
                              _vm._v("Service Charge"),
                            ]),
                            _vm._v(" "),
                            _c(
                              "option",
                              { attrs: { value: "advance_payment" } },
                              [_vm._v("Customer Advance/Deposit")]
                            ),
                            _vm._v(" "),
                            _c("option", { attrs: { value: "shipping" } }, [
                              _vm._v("Shipping Fee"),
                            ]),
                            _vm._v(" "),
                            _c("option", { attrs: { value: "interest" } }, [
                              _vm._v("Interest Earning"),
                            ]),
                            _vm._v(" "),
                            _c("option", { attrs: { value: "vat" } }, [
                              _vm._v("VAT Received"),
                            ]),
                            _vm._v(" "),
                            _c(
                              "option",
                              { attrs: { value: "purchase_return" } },
                              [_vm._v("Purchase Return")]
                            ),
                            _vm._v(" "),
                            _c(
                              "option",
                              { attrs: { value: "stock_introduced" } },
                              [_vm._v("Stock Introduced")]
                            ),
                            _vm._v(" "),
                            _c("option", { attrs: { value: "discount" } }, [
                              _vm._v("Discount Received"),
                            ]),
                            _vm._v(" "),
                            _c(
                              "option",
                              { attrs: { value: "debt_repayment" } },
                              [_vm._v("Debt Repayment")]
                            ),
                            _vm._v(" "),
                            _c(
                              "option",
                              { attrs: { value: "gift_received" } },
                              [_vm._v("Gift Received")]
                            ),
                            _vm._v(" "),
                            _c("option", { attrs: { value: "others" } }, [
                              _vm._v("Other Fees"),
                            ]),
                          ]
                        ),
                      ])
                    : _vm.type == "outflows"
                    ? _c("div", [
                        _c("label", { staticClass: "db fade pv1" }, [
                          _vm._v("Others - outflow"),
                        ]),
                        _vm._v(" "),
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.subtype,
                                expression: "subtype",
                              },
                            ],
                            staticClass: "w-100",
                            on: {
                              change: function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.subtype = $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              },
                            },
                          },
                          [
                            _c("option", { attrs: { value: "" } }, [
                              _vm._v("-Category-"),
                            ]),
                            _vm._v(" "),
                            _c("option", { attrs: { value: "asset" } }, [
                              _vm._v("Asset Purchase"),
                            ]),
                            _vm._v(" "),
                            _c("option", { attrs: { value: "loan" } }, [
                              _vm._v("Loan Repayment"),
                            ]),
                            _vm._v(" "),
                            _c("option", { attrs: { value: "cc" } }, [
                              _vm._v("Credit Card Payment"),
                            ]),
                            _vm._v(" "),
                            _c("option", { attrs: { value: "drawings" } }, [
                              _vm._v("Drawings"),
                            ]),
                            _vm._v(" "),
                            _c("option", { attrs: { value: "sales_return" } }, [
                              _vm._v("Sales Return"),
                            ]),
                            _vm._v(" "),
                            _c(
                              "option",
                              { attrs: { value: "damaged_stock" } },
                              [_vm._v("Damaged Stock")]
                            ),
                            _vm._v(" "),
                            _c(
                              "option",
                              { attrs: { value: "missing_stock" } },
                              [_vm._v("Missing Stock")]
                            ),
                            _vm._v(" "),
                            _c(
                              "option",
                              { attrs: { value: "promotion_stock" } },
                              [_vm._v("Stock used in promotion")]
                            ),
                            _vm._v(" "),
                            _c("option", { attrs: { value: "loans_given" } }, [
                              _vm._v("Loans Given out"),
                            ]),
                            _vm._v(" "),
                            _c("option", { attrs: { value: "bad_debt" } }, [
                              _vm._v("Bad Debt"),
                            ]),
                            _vm._v(" "),
                            _c(
                              "option",
                              { attrs: { value: "vat_remittance" } },
                              [_vm._v("VAT Remittance")]
                            ),
                            _vm._v(" "),
                            _c("option", { attrs: { value: "others" } }, [
                              _vm._v("Others"),
                            ]),
                          ]
                        ),
                      ])
                    : _vm._e(),
                ])
              : _vm._e(),
          ]),
          _vm._v(" "),
          _vm.type == "expenses"
            ? _c("div", [
                _c("div", [
                  _c("label", { staticClass: "db fade pv1" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.prepayment,
                          expression: "prepayment",
                        },
                      ],
                      attrs: { type: "checkbox" },
                      domProps: {
                        checked: Array.isArray(_vm.prepayment)
                          ? _vm._i(_vm.prepayment, null) > -1
                          : _vm.prepayment,
                      },
                      on: {
                        change: function ($event) {
                          var $$a = _vm.prepayment,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 && (_vm.prepayment = $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.prepayment = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.prepayment = $$c
                          }
                        },
                      },
                    }),
                    _vm._v(" This is a prepayment."),
                  ]),
                ]),
                _vm._v(" "),
                _vm.prepayment
                  ? _c(
                      "div",
                      {
                        staticClass: "flex justify-between items-center w-100",
                      },
                      [
                        _c("div", { staticClass: "w-50 mr1" }, [
                          _c("label", { staticClass: "db fade pv1" }, [
                            _vm._v("Number of months"),
                          ]),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.prepayment_months,
                                expression: "prepayment_months",
                              },
                            ],
                            staticClass: "w-100",
                            attrs: { type: "number" },
                            domProps: { value: _vm.prepayment_months },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.prepayment_months = $event.target.value
                              },
                            },
                          }),
                        ]),
                        _vm._v(" "),
                        _vm._m(0),
                      ]
                    )
                  : _vm._e(),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.subtype == "purchase_return" || _vm.subtype == "sales_return"
            ? _c("div", { staticClass: "pv1" }, [
                _c("label", { staticClass: "db fade pv1" }, [
                  _vm._v("Reference transaction ID"),
                ]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.parent_ref,
                      expression: "parent_ref",
                    },
                  ],
                  staticClass: "w-100",
                  attrs: { type: "text" },
                  domProps: { value: _vm.parent_ref },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.parent_ref = $event.target.value
                    },
                  },
                }),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.type == "sales" ||
          _vm.type == "purchases" ||
          _vm.subtype == "stock_introduced" ||
          _vm.subtype == "purchase_return" ||
          _vm.subtype == "sales_return" ||
          /stock/.test(_vm.subtype)
            ? _c("div", { staticClass: "pv1" }, [
                _c(
                  "div",
                  [
                    _c("div", { staticClass: "flex items-center" }, [
                      _c("label", { staticClass: "db fade b pv1 mr2" }, [
                        _vm._v("Product(s)"),
                      ]),
                      _vm._v(" "),
                      _vm.type == "purchases"
                        ? _c("small", [
                            _c(
                              "a",
                              {
                                attrs: { href: "#" },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    _vm.productModal = true
                                  },
                                },
                              },
                              [_vm._v("Create new product")]
                            ),
                          ])
                        : _vm._e(),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "flex justify-between w-100" }, [
                      _c(
                        "div",
                        {
                          staticClass: "mr1",
                          class:
                            _vm.subtype == "purchase_return" ||
                            _vm.subtype == "sales_return" ||
                            /stock/.test(_vm.subtype)
                              ? "w-two-thirds"
                              : "w-third",
                        },
                        [
                          _c("label", { staticClass: "db fade pv1" }, [
                            _vm._v("Product"),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _vm.type == "sales" || _vm.type == "purchases"
                        ? _c("div", { staticClass: "w-third mr1 ml1" }, [
                            _c(
                              "label",
                              { staticClass: "db fade pv1" },
                              [
                                _vm.type == "purchases"
                                  ? [_vm._v("Purchase")]
                                  : [_vm._v("Sales")],
                                _vm._v(" price"),
                              ],
                              2
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm._m(1),
                    ]),
                    _vm._v(" "),
                    _vm._l(_vm.products, function (product, index) {
                      return _c(
                        "div",
                        {
                          key: index,
                          staticClass: "flex justify-between w-100 pv1",
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "mr1",
                              class:
                                _vm.subtype == "purchase_return" ||
                                _vm.subtype == "sales_return" ||
                                /stock/.test(_vm.subtype)
                                  ? "w-two-thirds"
                                  : "w-third",
                            },
                            [
                              _c("v-select", {
                                staticStyle: { "min-width": "150px" },
                                attrs: { options: _vm.tmpOptions },
                                on: { search: _vm.fetchProduct },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "no-options",
                                      fn: function (ref) {
                                        var search = ref.search
                                        var searching = ref.searching
                                        var loading = ref.loading
                                        return [
                                          _vm._v(
                                            "\n                  Type product name...\n                "
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                                model: {
                                  value: product.product,
                                  callback: function ($$v) {
                                    _vm.$set(product, "product", $$v)
                                  },
                                  expression: "product.product",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.type == "sales" || _vm.type == "purchases"
                            ? _c("div", { staticClass: "w-third ml1 mr1" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: product.price,
                                      expression: "product.price",
                                    },
                                  ],
                                  staticClass: "w-100",
                                  attrs: { type: "text" },
                                  domProps: { value: product.price },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        product,
                                        "price",
                                        $event.target.value
                                      )
                                    },
                                  },
                                }),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _c("div", { staticClass: "w-third ml1" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: product.quantity,
                                  expression: "product.quantity",
                                },
                              ],
                              staticClass: "w-100",
                              attrs: { type: "number" },
                              domProps: { value: product.quantity },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    product,
                                    "quantity",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                          ]),
                        ]
                      )
                    }),
                  ],
                  2
                ),
                _vm._v(" "),
                _c(
                  "div",
                  _vm._l(_vm.additionalOptions, function (o) {
                    return _c("div", { key: o.key, staticClass: "pv1" }, [
                      _c("label", { staticClass: "db fade pv1" }, [
                        _vm._v(_vm._s(o.label)),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "flex justify-between items-center w-100",
                        },
                        [
                          _c("div", { staticClass: "w-50 mr1" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: o.value,
                                  expression: "o.value",
                                },
                              ],
                              staticClass: "w-100",
                              attrs: { type: "text" },
                              domProps: { value: o.value },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(o, "value", $event.target.value)
                                },
                              },
                            }),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "w-50 ml1" }, [
                            _vm._v("\n                Fixed "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: o.fixed,
                                  expression: "o.fixed",
                                },
                              ],
                              staticClass: "mr2",
                              attrs: { type: "radio", value: "Y" },
                              domProps: { checked: _vm._q(o.fixed, "Y") },
                              on: {
                                change: function ($event) {
                                  return _vm.$set(o, "fixed", "Y")
                                },
                              },
                            }),
                            _vm._v(" Percentage\n                "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: o.fixed,
                                  expression: "o.fixed",
                                },
                              ],
                              attrs: { type: "radio", value: "N" },
                              domProps: { checked: _vm._q(o.fixed, "N") },
                              on: {
                                change: function ($event) {
                                  return _vm.$set(o, "fixed", "N")
                                },
                              },
                            }),
                          ]),
                        ]
                      ),
                    ])
                  }),
                  0
                ),
                _vm._v(" "),
                _vm.type == "sales"
                  ? _c("small", [
                      _c(
                        "a",
                        {
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.moreProducts.apply(null, arguments)
                            },
                          },
                        },
                        [_vm._v("Add more products")]
                      ),
                      _vm._v(" |\n          "),
                      _c(
                        "a",
                        {
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.addOtherOptions("shipping")
                            },
                          },
                        },
                        [_vm._v("Add Shipping")]
                      ),
                      _vm._v(" |\n          "),
                      _c(
                        "a",
                        {
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.addOtherOptions("vat")
                            },
                          },
                        },
                        [_vm._v("Add VAT")]
                      ),
                      _vm._v(" |\n          "),
                      _c(
                        "a",
                        {
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.addOtherOptions("commission")
                            },
                          },
                        },
                        [_vm._v("Add Commission")]
                      ),
                      _vm._v(" |\n          "),
                      _c(
                        "a",
                        {
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.addOtherOptions("discount")
                            },
                          },
                        },
                        [_vm._v("Add Discount")]
                      ),
                      _vm._v(" |\n          "),
                      _c(
                        "a",
                        {
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.addOtherOptions("other_fees")
                            },
                          },
                        },
                        [_vm._v("Add Other Fees")]
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.type == "purchases"
                  ? _c("small", [
                      _c(
                        "a",
                        {
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.moreProducts.apply(null, arguments)
                            },
                          },
                        },
                        [_vm._v("Add more products")]
                      ),
                      _vm._v(" |\n          "),
                      _c(
                        "a",
                        {
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.addOtherOptions("shipping")
                            },
                          },
                        },
                        [_vm._v("Add Shipping")]
                      ),
                      _vm._v(" |\n          "),
                      _c(
                        "a",
                        {
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.addOtherOptions("trx")
                            },
                          },
                        },
                        [_vm._v("Add Cost of Transaction")]
                      ),
                      _vm._v(" |\n          "),
                      _c(
                        "a",
                        {
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.addOtherOptions("discount")
                            },
                          },
                        },
                        [_vm._v("Add Discount")]
                      ),
                      _vm._v(" |\n          "),
                      _c(
                        "a",
                        {
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.addOtherOptions("other_cost")
                            },
                          },
                        },
                        [_vm._v("Other cost")]
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.subtype == "purchase_return" ||
                _vm.subtype == "sales_return" ||
                /stock/.test(_vm.subtype)
                  ? _c("small", [
                      _c(
                        "a",
                        {
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.moreProducts.apply(null, arguments)
                            },
                          },
                        },
                        [_vm._v("Add more products")]
                      ),
                    ])
                  : _vm._e(),
              ])
            : _c("div", { staticClass: "pv1" }, [
                _c("label", { staticClass: "db fade pv1" }, [
                  _vm._v("Description"),
                ]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.description,
                      expression: "description",
                    },
                  ],
                  staticClass: "w-100",
                  attrs: { type: "text" },
                  domProps: { value: _vm.description },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.description = $event.target.value
                    },
                  },
                }),
              ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "pv1" },
            [
              _c("label", { staticClass: "db fade pv1" }, [
                _vm._v("Entity name"),
              ]),
              _vm._v(" "),
              _c("v-select", {
                staticStyle: { "min-width": "150px" },
                attrs: { taggable: true, options: _vm.tmpOptions },
                on: { search: _vm.fetchEntity },
                scopedSlots: _vm._u([
                  {
                    key: "no-options",
                    fn: function (ref) {
                      var search = ref.search
                      var searching = ref.searching
                      var loading = ref.loading
                      return [
                        _vm._v("\n            Type entity name...\n          "),
                      ]
                    },
                  },
                ]),
                model: {
                  value: _vm.entity,
                  callback: function ($$v) {
                    _vm.entity = $$v
                  },
                  expression: "entity",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "pv1" }, [
            _c("label", { staticClass: "db fade pv1" }, [
              _vm._v("Reference/Invoice number"),
            ]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.reference,
                  expression: "reference",
                },
              ],
              staticClass: "w-100",
              attrs: { type: "text", readonly: "" },
              domProps: { value: _vm.reference },
              on: {
                input: function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.reference = $event.target.value
                },
              },
            }),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "pv1 flex justify-between items-center w-100" },
            [
              _c("div", { staticClass: "w-50 mr1" }, [
                _c("label", { staticClass: "db fade pv1" }, [
                  _vm._v("Amount/Value"),
                ]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.amount,
                      expression: "amount",
                    },
                  ],
                  staticClass: "w-100",
                  attrs: { type: "number" },
                  domProps: { value: _vm.amount },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.amount = $event.target.value
                    },
                  },
                }),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "w-50 ml1" }, [
                _c("label", { staticClass: "db fade pv1" }, [_vm._v("Date")]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.date,
                      expression: "date",
                    },
                  ],
                  staticClass: "w-100",
                  attrs: { type: "date" },
                  domProps: { value: _vm.date },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.date = $event.target.value
                    },
                  },
                }),
              ]),
            ]
          ),
          _vm._v(" "),
          _vm.type == "sales" || _vm.type == "purchases"
            ? _c("div", [
                _c("label", { staticClass: "db fade pv1" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.credit,
                        expression: "credit",
                      },
                    ],
                    attrs: { type: "checkbox" },
                    domProps: {
                      checked: Array.isArray(_vm.credit)
                        ? _vm._i(_vm.credit, null) > -1
                        : _vm.credit,
                    },
                    on: {
                      change: function ($event) {
                        var $$a = _vm.credit,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 && (_vm.credit = $$a.concat([$$v]))
                          } else {
                            $$i > -1 &&
                              (_vm.credit = $$a
                                .slice(0, $$i)
                                .concat($$a.slice($$i + 1)))
                          }
                        } else {
                          _vm.credit = $$c
                        }
                      },
                    },
                  }),
                  _vm._v(" This is a credit transaction."),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "pv1 flex justify-between items-center w-100",
                    style: _vm.credit ? "" : "display:none !important",
                  },
                  [
                    _c("div", { staticClass: "w-50 mr1" }, [
                      _c("label", { staticClass: "db fade pv1" }, [
                        _vm._v("Upfront payment"),
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.initial_deposit,
                            expression: "initial_deposit",
                          },
                        ],
                        staticClass: "w-100",
                        attrs: { type: "number" },
                        domProps: { value: _vm.initial_deposit },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.initial_deposit = $event.target.value
                          },
                        },
                      }),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "w-50 ml1" }, [
                      _c("label", { staticClass: "db fade pv1" }, [
                        _vm._v("Balance due date"),
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.due_date,
                            expression: "due_date",
                          },
                        ],
                        staticClass: "w-100",
                        attrs: { type: "date" },
                        domProps: { value: _vm.due_date },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.due_date = $event.target.value
                          },
                        },
                      }),
                    ]),
                  ]
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "pt2" }, [
            _c(
              "button",
              {
                staticClass: "w-100",
                attrs: { disabled: _vm.addingTrx },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.addTrx.apply(null, arguments)
                  },
                },
              },
              [
                _vm._v(
                  "\n          " +
                    _vm._s(
                      _vm.addingTrx
                        ? "Creating Transaction..."
                        : "Create Transaction"
                    ) +
                    "\n        "
                ),
              ]
            ),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "w-50 mr1 ml1" }, [
      _c("label", { staticClass: "db fade pv1" }, [_vm._v("End date")]),
      _vm._v(" "),
      _c("input", {
        staticClass: "w-100",
        attrs: { type: "date", readonly: "" },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "w-third ml1" }, [
      _c("label", { staticClass: "db fade pv1" }, [_vm._v("Quantity")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }