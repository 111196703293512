<template>
  <div>
    <div class="mid fixed w-90 w-80-l h-100" style="border-right:0;background-color:#f5f6fa">
      <div class="w-70 center pv5">
        <div class="">
          <div class="fl w-25 tc pa2">
            <div class="box ph3 pt3 pb4">
              <h2 class="mb2">-</h2>
              <div class="truncate">Sales</div>
            </div>
          </div>
          <div class="fl w-25 tc pa2">
            <div class="box ph3 pt3 pb4">
              <h2 class="mb2">-</h2>
              <div class="truncate">Purchase</div>
            </div>
          </div>
          <div class="fl w-25 tc pa2">
            <div class="box ph3 pt3 pb4">
              <h2 class="mb2">-</h2>
              <div>Expense</div>
            </div>
          </div>
          <div class="fl w-25 tc pa2">
            <div class="box ph3 pt3 pb4">
              <h2 class="mb2">-</h2>
              <div class="truncate">Balance</div>
            </div>
          </div>
          <div class="cb"></div>
        </div>
        <table class="mt4 w-100">
          <thead>
            <tr>
              <th>Date</th>
              <th>ID</th>
              <th>Type</th>
              <th>Entity name</th>
              <th>Description</th>
              <th>Amount</th>
            </tr>
          </thead>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
}
</script>

<style scoped>
</style>