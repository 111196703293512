<template>
  <div>
    <div>
      <h3 class="asc ma0 pb2">Organisation</h3>
      <div class="pv2 info" v-if="info">{{ info }}</div>
      <div class="pv2 error" v-if="error">{{ error }}</div>
      <form method="post" style="width:250px" @submit.prevent="updateOrg">
        <div class="pv1">
          <label class="db fade pv1">Name</label>
          <input type="text" :value="org.name" class="w-100 org-name">
          <small class="fade db pt1" style="line-height:1.4em">Emails to your customers will be sent with this name.</small>
        </div>
        <div class="pv1">
          <label class="db fade pv1">"From" email</label>
          <small class="fade db pb1" style="line-height:1.4em">(requires custom domain)</small>
          <input type="email" v-model="org.email" class="w-100" :readonly="!org.billing || !org.billing.pro">
        </div>
        <div class="pt2">
          <button class="w-100" type="submit" :disabled="working">{{ working ? 'Updating...' : 'Update' }}</button>
        </div>
      </form>
    </div>
    <div class="mt4" v-if="org.tracking">
      <h3 class="asc ma0 pb2">Tracking</h3>
      <div class="pv1">
        <label class="fade db pv1"><input type="checkbox" v-model="org.tracking.open" @change="updateTracking"> Track when billing emails are opened by customers</label>
        <label class="fade db pv1"><input type="checkbox" v-model="org.tracking.click" @change="updateTracking"> Track when links within billing emails are clicked by customers</label>
      </div>
    </div>
    <div class="mt4">
      <h3 class="asc ma0">Signature (Pro)</h3>
      <div v-if="!org.billing || !org.billing.pro">
        <small><router-link to="/settings/billing">Upgrade to Pro</router-link> to disable signature</small>
      </div>
      <div class="pv1">
        <label class="fade db pv1" :style="org.billing && org.billing.pro ? '':'opacity:0.6'"><input type="checkbox" v-model="org.unsigned" :disabled="!org.billing || !org.billing.pro" @change="updateBranding"> Remove "sent from Plover" email signature</label>
      </div>
    </div>
    <div class="mt4">
      <h3 class="asc ma0">Custom domain (Pro)</h3>
      <div v-if="!org.billing || !org.billing.pro">
        <small><router-link to="/settings/billing">Upgrade to Pro</router-link> to send from your own domain</small>
      </div>
      <div v-if="org.domain.dns && org.domain.verified">
        <small class="fade">You have been verified. Please update your "From" email above</small>
      </div>
      <form method="post" @submit.prevent="setupDomain">
      <div class="pv1">
        <label class="db fade pv1" :style="org.billing && org.billing.pro ? '':'opacity:0.6'">Domain name</label>
        <input type="text" v-model="org.domain.name" :disabled="!org.billing || !org.billing.pro"> <button type="submit" v-if="!org.domain.dns" :disabled="working || !org.billing" :style="org.billing && org.billing.pro ? '':'opacity:0.6'">{{ working ? 'Updating...' : 'Update' }}</button> <button type="button" v-if="org.domain.dns && !org.domain.verified" class="button-default" @click="verifyModal = true">Verify</button> <button type="button" v-if="org.domain.dns" class="button-del" @click="removeDomain">Remove</button>
      </div>
      </form>
    </div>
    <div class="mt4">
      <h3 class="asc ma0 pb2">Danger zone</h3>
      <div class="pt2" v-show="!showConfirm"><button class="button-del" @click="toggleConfirm">Delete account</button></div>
      <form method="post" v-show="showConfirm" @submit.prevent="deleteAccount">
        <div class="pt2 measure">Are you sure you want to delete this account? Your event emails will no longer work and all related data to this account will be removed from our service.</div>
        <div class="pt2"><button class="button-del" type="submit">Yes, delete account</button> <button class="button button-plain" @click="toggleConfirm" type="button">Cancel</button></div>
      </form>
    </div>
    <div class="modal" v-show="verifyModal" @keyup.esc="verifyModal = false" tabindex="0">
      <div class="w-40-ns w-50 center modal-body mt4 pa4">
        <div class="pv2 info" v-if="info">{{ info }}</div>
        <div class="pv2 error" v-if="error">{{ error }}</div>
        <div class="mb3">Update your domain's DNS with the following values. Wait for some time for the values to propagate and click the verify button.</div>
        <table class="w-100">
          <tr>
            <th class="pa2 tl">Type</th>
            <th class="pa2 tl">Name/Priority</th>
            <th class="pa2 tl">Value</th>
          </tr>
          <tr v-for="(dns, index) of org.domain.dns" :key="index">
            <td valign="top" class="pa2 fade code f6"><div class="flex"><check-icon style="color:#390" class="pr2" v-if="dns.valid=='valid'" />{{dns.record_type}}</div></td>
            <td valign="top" class="pa2 fade code f6">{{dns.priority ? dns.priority : dns.name }}</td>
            <td valign="top" class="pa2 fade code f6"><div style="word-break:break-word">{{dns.value}}</div></td>
          </tr>
        </table>
        <div class="mt3">
          <button class="button-default" style="margin-bottom:0" @click="verifyDomain" :disabled="working">{{ working ? 'Verifying...' : 'Verify' }}</button> <button class="button-plain" style="margin-bottom:0" @click="verifyModal = false" type="button">Cancel</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { CheckIcon } from 'vue-feather-icons'
export default {
  data() {
    return {
      error: '',
      info: '',
      verifyModal: false,
      working: false,
      showConfirm: false
    }
  },
  computed: {
    org() {
      return this.$store.state.org
    }
  },
  components: {
    CheckIcon
  },
  methods: {
    toggleConfirm () {
      this.showConfirm = !this.showConfirm
    },
    async updateTracking () {
      this.error = ''
      this.info = ''
      const response = await fetch('/api/org', {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json;charset=utf-8' },
        body: JSON.stringify({
          tracking: this.org.tracking
        })
      })
      let data = {}
      try {
        data = await response.json()
      } catch (j) {}
      if (response.status !== 200) {
        this.error = data.error ? data.error : 'There has been a connection error. Try again later.'
      } else {
        this.info = 'Update successful.'
      }
    },
    async updateBranding () {
      this.error = ''
      this.info = ''
      const response = await fetch('/api/org', {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json;charset=utf-8' },
        body: JSON.stringify({
          unsigned: this.org.unsigned
        })
      })
      let data = {}
      try {
        data = await response.json()
      } catch (j) {}
      if (response.status !== 200) {
        this.error = data.error ? data.error : 'There has been a connection error. Try again later.'
      } else {
        this.info = 'Update successful.'
      }
    },
    async updateOrg () {
      this.error = ''
      this.info = ''
      this.working = true
      const name = document.querySelector('.org-name').value
      const response = await fetch('/api/org', {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json;charset=utf-8' },
        body: JSON.stringify({
          name
        })
      })
      let data = {}
      try {
        data = await response.json()
      } catch (j) {}
      if (response.status !== 200) {
        this.error = data.error ? data.error : 'There has been a connection error. Try again later.'
      } else {
        this.$store.commit('updateOrgName', name)
        this.info = 'Organisation updated successfully.'
      }
      this.working = false
    },
    async verifyDomain () {
      this.error = ''
      this.info = ''
      this.working = true
      const name = document.querySelector('.org-name').value
      const response = await fetch('/api/org/domains/verify', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json;charset=utf-8' },
        body: JSON.stringify({
          domain: this.org.domain.name
        })
      })
      let data = {}
      try {
        data = await response.json()
      } catch (j) {}
      if (response.status !== 200) {
        this.error = data.error ? data.error : 'There has been a connection error. Try again later.'
      } else {
        this.$store.commit('updateOrgDomain', data)
        if (data.verified) {
          this.info = 'Domain added. Add the required DNS settings and click verify.'
        } else {
          this.error = 'Domain not yet verified.'
        }
      }
      // this.verifyModal = false
      this.working = false
    },
    async setupDomain () {
      this.error = ''
      this.info = ''
      this.working = true
      const name = document.querySelector('.org-name').value
      const response = await fetch('/api/org', {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json;charset=utf-8' },
        body: JSON.stringify({
          domain: this.org.domain.name
        })
      })
      let data = {}
      try {
        data = await response.json()
      } catch (j) {}
      if (response.status !== 200) {
        this.error = data.error ? data.error : 'There has been a connection error. Try again later.'
      } else {
        this.$store.commit('updateOrgDomain', data)
        this.info = 'Domain added. Add the required DNS settings and click verify.'
      }
      this.working = false
    },
    async removeDomain () {
      const response = await fetch('/api/org/domains', {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json;charset=utf-8' }
      })
      // console.log(response)
      if (response.status !== 200) {
        this.error = 'There has been an error deleting this account.'
      } else {
        this.$store.commit('updateOrgDomain', {name:{}})
      }
    },
    async deleteAccount () {
      const response = await fetch('/api/org', {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json;charset=utf-8' }
      })
      if (response.status !== 200) {
        this.error = 'There has been an error deleting this account.'
      } else {
        window.location.href = this.org.accounts.length ? '/app' : '/'
      }
    }
  }
}
</script>

<style scoped>
</style>