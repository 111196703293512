<template>
  <div>
    <div>
      <h3 class="asc ma0 pb2" v-if="isAdmin">Invite members</h3>
      <form method="post" @submit.prevent="sendInvite" v-if="isAdmin">
        <div class="pv1">
          <input type="email" v-model="invite" placeholder="Email" required> <button type="submit" :disabled="working">{{ working ? 'Adding...' : 'Invite' }}</button>
        </div>
      </form>
    </div>
    <div class="info" v-if="info">{{ info }}</div>
    <div class="pv2 error" v-if="error">{{ error }}</div>
    <div class="mt4">
      <h3 class="asc ma0 pb2">Members</h3>
      <div v-for="member in org.members" :key="member.email" :class="['list-item', deleting.indexOf(member.email) > -1 ? 'deleting':'']">
        <div class="fr pt3 icon" v-if="member.email != org.user.email && isAdmin"><a href="#" v-if="member.pending" @click.prevent="sendInvite(member.email)"><email-send-icon title="Resend Invitation" data-tooltip="Resend Invitation" /></a> <a href="" @click.prevent="changeRole(member.email, member.role)"><key-outline-icon title="Change role" data-tooltip="Change role" /></a> <a href="" @click.prevent="confirmDelete(member.email)"><account-remove-icon title="Remove user" data-tooltip="Remove user" /></a></div>
        <div class="bb-line pv3">{{member.name}}<span class="fade">{{member.name ? ' ('+member.email+')' : member.email }}</span> <small :class="['tag', member.role === 'admin' ? 'tag-alt' : 'tag-alt-b']">{{member.role}}</small> <small class="tag" v-if="member.pending">pending</small></div>
      </div>
    </div>
    <div class="modal" v-show="modalOn" @keyup.esc="closeModal" tabindex="0">
      <div class="w-40-ns w-50 center modal-body mt6 pa4">
        <form method="post" @submit.prevent="deleteMember">
          <div class="mb3">Kindly confirm you want to remove the user by entering the email <strong>{{deleteEmail}}</strong> in the text field below.</div>
          <div class="pv2">
            <input type="email" :placeholder="deleteEmail" v-model="deleteValue" required>
          </div>
          <button type="submit" style="margin-bottom:0">Remove</button> <button class="button-plain" style="margin-bottom:0" @click.prevent="closeModal" type="button">Cancel</button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import KeyOutlineIcon from 'vue-material-design-icons/KeyOutline.vue'
import EmailSendIcon from 'vue-material-design-icons/EmailSend.vue'
import ShieldKeyOutlineIcon from 'vue-material-design-icons/ShieldKeyOutline.vue'
import AccountRemoveIcon from 'vue-material-design-icons/AccountRemove.vue'

export default {
  data() {
    return {
      invite: '',
      modalOn: false,
      deleting: [],
      deleteEmail: '',
      deleteValue: '',
      info: '',
      error: '',
      working: false
    }
  },
  components: {
    KeyOutlineIcon, ShieldKeyOutlineIcon, AccountRemoveIcon, EmailSendIcon
  },
  watch: {
    modalOn(v) {
      if (v) {
        document.addEventListener("keyup", this.onEscapeKeyUp)
      } else {
        document.removeEventListener("keyup", this.onEscapeKeyUp)
      }
    }
  },
  computed: {
    org () {
	    return this.$store.state.org
    },
    isAdmin () {
      if (this.$store.state.org.user) {
        return this.$store.state.org.user.role === 'admin'
      }
      return false
    }
  },
  methods: {
    async sendInvite (e) {
      // Check if emails
      this.error = ''
      this.info = ''
      this.working = true
      const email = (e && typeof e === 'string') ? e : this.invite.trim().toLowerCase()
      let pending = false
      for (const member of this.org.members) {
        if (member.email === email) {
          if (member.pending) {
            pending = true
          } else {
            this.info = 'User already a member of team.'
            this.working = false
            return
          }
        }
      }

      // API
      const response = await fetch('/api/org/members', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json;charset=utf-8' },
        body: JSON.stringify({ email })
      })
      let data = {}
      try {
        data = await response.json()
      } catch (j) {}
      if (response.status !== 200) {
        this.error = data.error ? data.error : 'There has been a connection error. Try again later.'
      } else {
        this.invite = ''
        if (pending) {
          this.info = 'Invite email resent'
        } else {
          this.$store.commit('addMember', {
            name: '',
            email,
            pending: true,
            role: 'member'
          })
          this.info = 'Invite sent.'
        }
      }
      this.working = false
    },
    async confirmDelete (email) {
      this.modalOn = true
      this.deleteEmail = email
    },
    async deleteMember () {
      this.modalOn = false
      this.error = ''
      this.info = ''
      if (this.deleteValue.trim().toLowerCase() !== this.deleteEmail) {
        this.error = 'Wrong email entered in confirmation field.'
        return
      }
      this.deleting.push(this.deleteEmail)
      const response = await fetch('/api/org/members', {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json;charset=utf-8' },
        body: JSON.stringify({ email: this.deleteEmail })
      })
      let data = {}
      try {
        data = await response.json()
      } catch (j) {}
      this.deleting.splice(this.deleting.indexOf(this.deleteEmail), 1)
      if (response.status !== 200) {
        this.error = data.error ? data.error : 'There has been a connection error. Try again later.'
      } else {
        this.info = 'Member removed'
        this.$store.commit('removeMember', this.deleteEmail)
      }
      this.deleteEmail = ''
    },
    async changeRole (email, role) {
      this.error = ''
      role = (role === 'admin') ? 'member' : 'admin'
      this.info = `Role changed to ${role}`
      this.$store.commit('toggleRole', email)

      const response = await fetch(`/api/org/members/${email}`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json;charset=utf-8' },
        body: JSON.stringify({ role })
      })
      let data = {}
      try {
        data = await response.json()
      } catch (j) {}
      // Failed, toggle back
      if (response.status !== 200) {
        this.info = ''
        this.error = data.error ? data.error : 'There has been a connection error. Try again later.'
        this.$store.commit('toggleRole', email)
      }
    },
    closeModal () {
      this.modalOn = false
    },
    onEscapeKeyUp(event) {
      if (event.which === 27) {
        this.modalOn = false
      }
    }
  }
}
</script>

<style scoped>
svg {
  width: 20px;
  height: 20px;
  margin-left: 5px;
  position: relative;
  top: 2px
}
.icon {
  display: none;
}
.deleting {
  opacity: 0.4;
}
.list-item:hover .icon {
  display: block
}
.list-item.deleting:hover .icon {
  display: none
}
.icon a svg {
  color: #777;
}
.icon a:hover svg {
  color: #444;
}
</style>