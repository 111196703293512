<template>
  <div>
    <div class="mid fixed w-90 w-80-l h-100" style="border-right:0;background-color:#f5f6fa">
      <div class="w-90 center pv5">
        <div class="error pb3" v-if="error">{{ error }}</div>
        <div class="pa2 fr"><span v-show="showmonth">This month</span><a href="#" class="fade" v-show="!showmonth" @click.prevent="showmonth = true">This Month</a> <span v-show="!showmonth" class="ml2 dib">All time</span><a href="#" class="ml2 fade" v-show="showmonth" @click.prevent="showmonth = false">All time</a></div>
        <div class="pa2 b">&nbsp;</div>
        <div>
          <div class="fl w-50 tc pa2">
            <div class="box ph3 pt3 pb4">
              <div>Outstanding</div>
              <div class="mb2 flex justify-between">
                <div class="tc w-50">
                  <h2>1</h2>
                  <div>Payable</div>
                </div>
                <div class="tc w-50">
                  <h2>2</h2>
                  <div>Receivable</div>
                </div>
              </div>
            </div>
          </div>
          <div class="fl w-50 tc pa2">
            <div class="box ph3 pt3 pb4">
              <div>Past Due</div>
              <div class="mb2 flex justify-between">
                <div class="tc w-50">
                  <h2>1</h2>
                  <div>Payable</div>
                </div>
                <div class="tc w-50">
                  <h2>2</h2>
                  <div>Receivable</div>
                </div>
              </div>
            </div>
          </div>
          <div class="cb"></div>
        </div>
        <div>
          <div class="fl w-50 tc pa2">
            <div class="box ph3 pt3 pb4">
              <div>Due soon</div>
              <div class="mb2 flex justify-between">
                <div class="tc w-50">
                  <h2>1</h2>
                  <div>Payable</div>
                </div>
                <div class="tc w-50">
                  <h2>2</h2>
                  <div>Receivable</div>
                </div>
              </div>
            </div>
          </div>
          <div class="fl w-50 tc pa2">
            <div class="box ph3 pt3 pb4">
              <div>Average day</div>
              <div class="mb2 flex justify-between">
                <div class="tc w-50">
                  <h2>1</h2>
                  <div>Payable</div>
                </div>
                <div class="tc w-50">
                  <h2>2</h2>
                  <div>Receivable</div>
                </div>
              </div>
            </div>
          </div>
          <div class="cb"></div>
        </div>
        
        <h3>Payable</h3>
        <table class="mt4 w-100">
          <thead>
            <tr>
              <th>Customer name</th>
              <th>Total amount</th>
              <th>Balance due</th>
            </tr>
          </thead>
          <tr v-for="transaction of payables" :key="transaction._id">
            <td><router-link :to="'/entities/'+transaction._id">{{transaction.name}}</router-link></td>
            <td>{{transaction.payable_amount ? transaction.payable_amount.toLocaleString(): 0 }}</td>
            <td>{{transaction.payable_balance ? transaction.payable_balance.toLocaleString() : 0 }}</td>
          </tr>
        </table>
        
        <h3>Receivable</h3>
        <table class="mt4 w-100">
          <thead>
            <tr>
              <th>Customer name</th>
              <th>Total amount</th>
              <th>Balance due</th>
            </tr>
          </thead>
          <tr v-for="transaction of receivables" :key="transaction._id">
            <td><router-link :to="'/entities/'+transaction._id">{{transaction.name}}</router-link></td>
            <td>{{transaction.receivable_amount ? transaction.receivable_amount.toLocaleString(): 0 }}</td>
            <td>{{transaction.receivable_balance ? transaction.receivable_balance.toLocaleString() : 0 }}</td>
          </tr>
        </table>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      error: '',
      showmonth: false,
      receivables: [],
      payables: []
    }
  },
  mounted: async function () {
    try {
      this.error = ''
      let response = await fetch('/api/entities?has=payable', {
        headers: { 'Content-Type': 'application/json;charset=utf-8' }
      })
      let data = {}
      try {
        data = await response.json()
      } catch (j) {}
      if (response.status !== 200 || !data.data || !Array.isArray(data.data)) {
        this.error = data.error ? data.error : 'There has been a connection error. Try again later.'
        return;
      }
      this.payables = data.data
      response = await fetch('/api/entities?has=receivables', {
        headers: { 'Content-Type': 'application/json;charset=utf-8' }
      })
      data = {}
      try {
        data = await response.json()
      } catch (j) {}
      if (response.status !== 200 || !data.data || !Array.isArray(data.data)) {
        this.error = data.error ? data.error : 'There has been a connection error. Try again later.'
        return;
      }
      this.receivables = data.data
    } catch (e) {
      this.error = e.message
    }
  }
}
</script>

<style scoped>
</style>