var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "mid fixed w-90 w-80-l h-100",
        staticStyle: { "border-right": "0", "background-color": "#f5f6fa" },
      },
      [
        _c("div", { staticClass: "w-90 center pv5" }, [
          _vm.error
            ? _c("div", { staticClass: "error pb3" }, [
                _vm._v(_vm._s(_vm.error)),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "pa2 fr" }, [
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.showmonth,
                    expression: "showmonth",
                  },
                ],
              },
              [_vm._v("This month")]
            ),
            _c(
              "a",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.showmonth,
                    expression: "!showmonth",
                  },
                ],
                staticClass: "fade",
                attrs: { href: "#" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    _vm.showmonth = true
                  },
                },
              },
              [_vm._v("This Month")]
            ),
            _vm._v(" "),
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.showmonth,
                    expression: "!showmonth",
                  },
                ],
                staticClass: "ml2 dib",
              },
              [_vm._v("All time")]
            ),
            _c(
              "a",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.showmonth,
                    expression: "showmonth",
                  },
                ],
                staticClass: "ml2 fade",
                attrs: { href: "#" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    _vm.showmonth = false
                  },
                },
              },
              [_vm._v("All time")]
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "pa2 b" }, [_vm._v(" ")]),
          _vm._v(" "),
          _c("div", [
            _c("div", { staticClass: "fl w-25 tc pa2" }, [
              _c("div", { staticClass: "box ph3 pt3 pb4" }, [
                _c("h2", { staticClass: "mb2" }, [
                  _vm._v(_vm._s(_vm.loading ? "..." : 0)),
                ]),
                _vm._v(" "),
                _c("div", [_vm._v("Total Qty Purchase")]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "fl w-25 tc pa2" }, [
              _c("div", { staticClass: "box ph3 pt3 pb4" }, [
                _c("h2", { staticClass: "mb2" }, [
                  _vm._v(_vm._s(_vm.loading ? "..." : 0)),
                ]),
                _vm._v(" "),
                _c("div", [_vm._v("Total Cost")]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "fl w-25 tc pa2" }, [
              _c("div", { staticClass: "box ph3 pt3 pb4" }, [
                _c("h2", { staticClass: "mb2" }, [
                  _vm._v(_vm._s(_vm.loading ? "..." : 0)),
                ]),
                _vm._v(" "),
                _c("div", [_vm._v("Total Qty Sold")]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "fl w-25 tc pa2" }, [
              _c("div", { staticClass: "box ph3 pt3 pb4" }, [
                _c("h2", { staticClass: "mb2" }, [
                  _vm._v(_vm._s(_vm.loading ? "..." : 0)),
                ]),
                _vm._v(" "),
                _c("div", [_vm._v("Total Sales")]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "cb" }),
          ]),
          _vm._v(" "),
          _c("div", [
            _c("div", { staticClass: "fl w-third tc pa2" }, [
              _c("div", { staticClass: "box ph3 pt3 pb4" }, [
                _c("h2", { staticClass: "mb2" }, [
                  _vm._v(_vm._s(_vm.loading ? "..." : 0)),
                ]),
                _vm._v(" "),
                _c("div", [_vm._v("Total Stock on Hand")]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "fl w-third tc pa2" }, [
              _c("div", { staticClass: "box ph3 pt3 pb4" }, [
                _c("h2", { staticClass: "mb2" }, [
                  _vm._v(_vm._s(_vm.loading ? "..." : 0)),
                ]),
                _vm._v(" "),
                _c("div", [_vm._v("Total Value of Stock on Hand")]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "fl w-third tc pa2" }, [
              _c("div", { staticClass: "box ph3 pt3 pb4" }, [
                _c("h2", { staticClass: "mb2" }, [
                  _vm._v(_vm._s(_vm.loading ? "..." : 0)),
                ]),
                _vm._v(" "),
                _c("div", [_vm._v("Average Inventory Day")]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "cb" }),
          ]),
          _vm._v(" "),
          _vm._m(0),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("table", { staticClass: "mt4" }, [
      _c("thead", [
        _c("tr", { staticClass: "tc" }, [
          _c("th"),
          _vm._v(" "),
          _c("th", { attrs: { colspan: "3" } }, [_vm._v("Purchase")]),
          _vm._v(" "),
          _c("th", { attrs: { colspan: "3" } }, [_vm._v("Sales")]),
          _vm._v(" "),
          _c("th", { attrs: { colspan: "2" } }, [_vm._v("Stock on Hand")]),
          _vm._v(" "),
          _c("th", { attrs: { colspan: "2" } }, [_vm._v("Profitability")]),
        ]),
        _vm._v(" "),
        _c("tr", [
          _c("th", [_vm._v("Product name")]),
          _vm._v(" "),
          _c("th", [_vm._v("Total Qty")]),
          _vm._v(" "),
          _c("th", [_vm._v("Total Purchase Cost")]),
          _vm._v(" "),
          _c("th", [_vm._v("Average Cost")]),
          _vm._v(" "),
          _c("th", [_vm._v("Total Qty")]),
          _vm._v(" "),
          _c("th", [_vm._v("Total Sales Price")]),
          _vm._v(" "),
          _c("th", [_vm._v("Average Price")]),
          _vm._v(" "),
          _c("th", [_vm._v("Current Stock on Hand")]),
          _vm._v(" "),
          _c("th", [_vm._v("Value of Stock on Hand")]),
          _vm._v(" "),
          _c("th", [_vm._v("Average Profit")]),
          _vm._v(" "),
          _c("th", [_vm._v("Profitability rate")]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }