<template>
  <div>
    <div v-if="user">
      <h3 class="asc ma0 pb2">Profile info</h3>
      <div class="pv2 info" v-if="info">{{ info }}</div>
      <div class="pv2 error" v-if="error">{{ error }}</div>
      <form method="post" @submit.prevent="updateName">
        <div class="pv1">
          <label class="db fade pv1">Name</label>
          <input type="text" class="w-100" v-model="user.name">
        </div>
        <div class="pt2">
          <button class="w-100" type="submit" :disabled="submitName">{{ submitName ? 'Updating...' : 'Update' }}</button>
        </div>
      </form>
    </div>
    <div class="mt4">
      <h3 class="asc ma0 pb2">Password</h3>
      <form method="post" @submit.prevent="updatePassword">
        <div class="pv1">
          <label class="db fade pv1">Current password</label>
          <input type="password" class="w-100" v-model="oldPassword">
        </div>
        <div class="pv1">
          <label class="db fade pv1">New password</label>
          <input type="password" class="w-100" v-model="password">
        </div>
        <div class="pt2">
          <button class="w-100" type="submit" :disabled="submitPwd">{{ submitPwd ? 'Updating...' : 'Update' }}</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      error: '',
      info: '',
      password: '',
      oldPassword: '',
      submitName: false,
      submitEmail: false,
      submitPwd: false
    }
  },
  computed: {
    user () {
      return this.$store.state.org.user
    }
  },
  methods: {
    async updateName () {
      this.error = ''
      this.info = ''
      const o = {
        name: this.user.name
      }
      this.submitName = true
      const response = await fetch('/api/me', {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json;charset=utf-8' },
        body: JSON.stringify(o)
      })
      let data = {}
      try {
        data = await response.json()
      } catch (j) {}
      if (response.status !== 200) {
        this.error = data.error ? data.error : 'There has been a connection error. Try again later.'
      } else {
        this.$store.commit('updateUser', o)
        this.info = 'Profile updated successfully.'
      }
      this.submitName = false
    },

    async updatePassword () {
      this.submitPwd = true
      this.error = ''
      this.info = ''
      const o = {
        password: this.password,
        old_password: this.oldPassword
      }
      const response = await fetch('/api/me/password', {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json;charset=utf-8' },
        body: JSON.stringify(o)
      })
      let data = {}
      try {
        data = await response.json()
      } catch (j) {}
      if (response.status !== 200) {
        this.error = data.error
      } else {
        this.info = 'Profile updated successfully.'
      }
      this.password = ''
      this.oldPassword = ''
      this.submitPwd = false
    }
  }
}
</script>

<style scoped>
form {
  width: 250px;
}
</style>