<template>
  <div>
    <div class="mid fixed w-80-l w-90 h-100" style="border-right:0">
      <div class="w-70 center pv5">
        <ul class="line-menu">
          <li><router-link to="/settings/profile" active-class="active" class="pa2">Profile</router-link></li>
          <li><router-link to="/settings/org" active-class="active" class="pa2" v-if="isAdmin">Account</router-link></li>
          <li><router-link to="/settings/team" active-class="active" class="pa2">Team</router-link></li>
          <li><router-link to="/settings/billing" active-class="active" class="pa2" v-if="isAdmin">Billing</router-link></li>
        </ul>
        <div class="pv4">
          <router-view></router-view>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
    }
  },
  computed: {
    isAdmin () {
      if (this.$store.state.org.user) {
        return this.$store.state.org.user.role === 'admin'
      }
      return false
    }
  },
  methods: {
  }
}
</script>

<style scoped>
</style>