var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "mid fixed w-90 w-80-l h-100",
        staticStyle: { "border-right": "0", "background-color": "#f5f6fa" },
      },
      [
        _c("div", { staticClass: "w-90 center pv5" }, [
          _vm.error
            ? _c("div", { staticClass: "error pb3" }, [
                _vm._v(_vm._s(_vm.error)),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "pa2 fr" }, [
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.showmonth,
                    expression: "showmonth",
                  },
                ],
              },
              [_vm._v("This month")]
            ),
            _c(
              "a",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.showmonth,
                    expression: "!showmonth",
                  },
                ],
                staticClass: "fade",
                attrs: { href: "#" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    _vm.showmonth = true
                  },
                },
              },
              [_vm._v("This Month")]
            ),
            _vm._v(" "),
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.showmonth,
                    expression: "!showmonth",
                  },
                ],
                staticClass: "ml2 dib",
              },
              [_vm._v("All time")]
            ),
            _c(
              "a",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.showmonth,
                    expression: "showmonth",
                  },
                ],
                staticClass: "ml2 fade",
                attrs: { href: "#" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    _vm.showmonth = false
                  },
                },
              },
              [_vm._v("All time")]
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "pa2 b" }, [_vm._v(" ")]),
          _vm._v(" "),
          _c("div", [
            _c("div", { staticClass: "fl w-third tc pa2" }, [
              _c("div", { staticClass: "box ph3 pt3 pb4" }, [
                _c("h2", { staticClass: "mb2" }, [
                  _vm._v(_vm._s(_vm.loading ? "..." : 0)),
                ]),
                _vm._v(" "),
                _c("div", [_vm._v("Sales")]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "fl w-third tc pa2" }, [
              _c("div", { staticClass: "box ph3 pt3 pb4" }, [
                _c("h2", { staticClass: "mb2" }, [
                  _vm._v(_vm._s(_vm.loading ? "..." : 0)),
                ]),
                _vm._v(" "),
                _c("div", [_vm._v("Supplies")]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "fl w-third tc pa2" }, [
              _c("div", { staticClass: "box ph3 pt3 pb4" }, [
                _c("h2", { staticClass: "mb2" }, [
                  _vm._v(_vm._s(_vm.loading ? "..." : 0)),
                ]),
                _vm._v(" "),
                _c("div", [_vm._v("Investment")]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "cb" }),
          ]),
          _vm._v(" "),
          _c("div", [
            _c("div", { staticClass: "fl w-50 tc pa2" }, [
              _c("div", { staticClass: "box ph3 pt3 pb4" }, [
                _c("h2", { staticClass: "mb2" }, [
                  _vm._v(_vm._s(_vm.loading ? "..." : 0)),
                ]),
                _vm._v(" "),
                _c("div", [_vm._v("Debt")]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "fl w-50 tc pa2" }, [
              _c("div", { staticClass: "box ph3 pt3 pb4" }, [
                _c("h2", { staticClass: "mb2" }, [
                  _vm._v(_vm._s(_vm.loading ? "..." : 0)),
                ]),
                _vm._v(" "),
                _c("div", [_vm._v("Credit")]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "cb" }),
          ]),
          _vm._v(" "),
          _c("h3", [_vm._v("Sales/Purchases")]),
          _vm._v(" "),
          _c(
            "table",
            { staticClass: "mt4 w-100" },
            [
              _vm._m(0),
              _vm._v(" "),
              _vm._l(_vm.transactions.data, function (transaction) {
                return _c("tr", { key: transaction._id }, [
                  _c("td", [
                    _vm._v(_vm._s(new Date(transaction.date).toUTCString())),
                  ]),
                  _vm._v(" "),
                  _c("td", [
                    transaction.type === "settlement"
                      ? _c("div", [_vm._v("Debt repayment")])
                      : _c("div", [
                          _vm._v(
                            _vm._s(
                              transaction.subtype
                                ? transaction.subtype
                                : transaction.type
                            )
                          ),
                        ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "td",
                    [
                      _c(
                        "router-link",
                        { attrs: { to: "/inventory/" + transaction.product } },
                        [_vm._v(_vm._s(transaction.product_title))]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("td", [_vm._v(_vm._s(transaction.reference))]),
                  _vm._v(" "),
                  _c("td", [_vm._v(_vm._s(transaction.quantity))]),
                  _vm._v(" "),
                  _c("td", [
                    _vm._v(
                      _vm._s(
                        transaction.unit_price
                          ? transaction.unit_price.toLocaleString()
                          : "0"
                      )
                    ),
                  ]),
                  _vm._v(" "),
                  _c("td", [
                    _vm._v(
                      _vm._s(
                        transaction.amount
                          ? transaction.amount.toLocaleString()
                          : "0"
                      )
                    ),
                  ]),
                ])
              }),
            ],
            2
          ),
          _vm._v(" "),
          _c("h3", [_vm._v("Payables/Receivables")]),
          _vm._v(" "),
          _c(
            "table",
            { staticClass: "mt4 w-100" },
            [
              _vm._m(1),
              _vm._v(" "),
              _vm._l(_vm.debts.data, function (transaction) {
                return _c("tr", { key: transaction._id }, [
                  _c("td", [
                    _vm._v(_vm._s(new Date(transaction.date).toUTCString())),
                  ]),
                  _vm._v(" "),
                  _c("td", [
                    transaction.type === "settlement"
                      ? _c("div", [_vm._v("Debt repayment")])
                      : _c("div", [
                          _vm._v(
                            _vm._s(
                              transaction.subtype
                                ? transaction.subtype
                                : transaction.type
                            )
                          ),
                        ]),
                  ]),
                  _vm._v(" "),
                  _c("td", [_vm._v(_vm._s(transaction.reference))]),
                  _vm._v(" "),
                  _c(
                    "td",
                    [
                      transaction.product
                        ? _c(
                            "router-link",
                            {
                              attrs: {
                                to: "/inventory/" + transaction.product,
                              },
                            },
                            [_vm._v(_vm._s(transaction.product_title))]
                          )
                        : _c("span", [_vm._v(_vm._s(transaction.description))]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("td", [
                    _vm._v(
                      _vm._s(
                        transaction.amount
                          ? transaction.amount.toLocaleString()
                          : 0
                      )
                    ),
                  ]),
                  _vm._v(" "),
                  _c("td", [
                    _vm._v(
                      _vm._s(new Date(transaction.due_date).toUTCString())
                    ),
                  ]),
                ])
              }),
            ],
            2
          ),
          _vm._v(" "),
          _c("h3", [_vm._v("Others")]),
          _vm._v(" "),
          _c(
            "table",
            { staticClass: "mt4 w-100" },
            [
              _vm._m(2),
              _vm._v(" "),
              _vm._l(_vm.others.data, function (transaction) {
                return _c("tr", { key: transaction._id }, [
                  _c("td", [
                    _vm._v(_vm._s(new Date(transaction.date).toUTCString())),
                  ]),
                  _vm._v(" "),
                  _c("td", [_vm._v(_vm._s(transaction.subtype))]),
                  _vm._v(" "),
                  _c("td", [_vm._v(_vm._s(transaction.reference))]),
                  _vm._v(" "),
                  _c("td", [_vm._v(_vm._s(transaction.description))]),
                  _vm._v(" "),
                  _c("td", [
                    _vm._v(
                      _vm._s(
                        transaction.amount
                          ? transaction.amount.toLocaleString()
                          : 0
                      )
                    ),
                  ]),
                ])
              }),
            ],
            2
          ),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Date")]),
        _vm._v(" "),
        _c("th", [_vm._v("Type")]),
        _vm._v(" "),
        _c("th", [_vm._v("Product")]),
        _vm._v(" "),
        _c("th", [_vm._v("Invoice/Ref number")]),
        _vm._v(" "),
        _c("th", [_vm._v("Quantity")]),
        _vm._v(" "),
        _c("th", [_vm._v("Unit Cost/Price")]),
        _vm._v(" "),
        _c("th", [_vm._v("Total Cost/Price")]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Date")]),
        _vm._v(" "),
        _c("th", [_vm._v("Type")]),
        _vm._v(" "),
        _c("th", [_vm._v("Invoice/Ref number")]),
        _vm._v(" "),
        _c("th", [_vm._v("Description/Product")]),
        _vm._v(" "),
        _c("th", [_vm._v("Total Amount")]),
        _vm._v(" "),
        _c("th", [_vm._v("Due date")]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Date")]),
        _vm._v(" "),
        _c("th", [_vm._v("Type")]),
        _vm._v(" "),
        _c("th", [_vm._v("Invoice/Ref number")]),
        _vm._v(" "),
        _c("th", [_vm._v("Description")]),
        _vm._v(" "),
        _c("th", [_vm._v("Amount")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }