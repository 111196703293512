var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", [
      _c("h3", { staticClass: "asc ma0 pb2" }, [_vm._v("Organisation")]),
      _vm._v(" "),
      _vm.info
        ? _c("div", { staticClass: "pv2 info" }, [_vm._v(_vm._s(_vm.info))])
        : _vm._e(),
      _vm._v(" "),
      _vm.error
        ? _c("div", { staticClass: "pv2 error" }, [_vm._v(_vm._s(_vm.error))])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "form",
        {
          staticStyle: { width: "250px" },
          attrs: { method: "post" },
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.updateOrg.apply(null, arguments)
            },
          },
        },
        [
          _c("div", { staticClass: "pv1" }, [
            _c("label", { staticClass: "db fade pv1" }, [_vm._v("Name")]),
            _vm._v(" "),
            _c("input", {
              staticClass: "w-100 org-name",
              attrs: { type: "text" },
              domProps: { value: _vm.org.name },
            }),
            _vm._v(" "),
            _c(
              "small",
              {
                staticClass: "fade db pt1",
                staticStyle: { "line-height": "1.4em" },
              },
              [_vm._v("Emails to your customers will be sent with this name.")]
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "pv1" }, [
            _c("label", { staticClass: "db fade pv1" }, [
              _vm._v('"From" email'),
            ]),
            _vm._v(" "),
            _c(
              "small",
              {
                staticClass: "fade db pb1",
                staticStyle: { "line-height": "1.4em" },
              },
              [_vm._v("(requires custom domain)")]
            ),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.org.email,
                  expression: "org.email",
                },
              ],
              staticClass: "w-100",
              attrs: {
                type: "email",
                readonly: !_vm.org.billing || !_vm.org.billing.pro,
              },
              domProps: { value: _vm.org.email },
              on: {
                input: function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.org, "email", $event.target.value)
                },
              },
            }),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "pt2" }, [
            _c(
              "button",
              {
                staticClass: "w-100",
                attrs: { type: "submit", disabled: _vm.working },
              },
              [_vm._v(_vm._s(_vm.working ? "Updating..." : "Update"))]
            ),
          ]),
        ]
      ),
    ]),
    _vm._v(" "),
    _vm.org.tracking
      ? _c("div", { staticClass: "mt4" }, [
          _c("h3", { staticClass: "asc ma0 pb2" }, [_vm._v("Tracking")]),
          _vm._v(" "),
          _c("div", { staticClass: "pv1" }, [
            _c("label", { staticClass: "fade db pv1" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.org.tracking.open,
                    expression: "org.tracking.open",
                  },
                ],
                attrs: { type: "checkbox" },
                domProps: {
                  checked: Array.isArray(_vm.org.tracking.open)
                    ? _vm._i(_vm.org.tracking.open, null) > -1
                    : _vm.org.tracking.open,
                },
                on: {
                  change: [
                    function ($event) {
                      var $$a = _vm.org.tracking.open,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 &&
                            _vm.$set(
                              _vm.org.tracking,
                              "open",
                              $$a.concat([$$v])
                            )
                        } else {
                          $$i > -1 &&
                            _vm.$set(
                              _vm.org.tracking,
                              "open",
                              $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                            )
                        }
                      } else {
                        _vm.$set(_vm.org.tracking, "open", $$c)
                      }
                    },
                    _vm.updateTracking,
                  ],
                },
              }),
              _vm._v(" Track when billing emails are opened by customers"),
            ]),
            _vm._v(" "),
            _c("label", { staticClass: "fade db pv1" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.org.tracking.click,
                    expression: "org.tracking.click",
                  },
                ],
                attrs: { type: "checkbox" },
                domProps: {
                  checked: Array.isArray(_vm.org.tracking.click)
                    ? _vm._i(_vm.org.tracking.click, null) > -1
                    : _vm.org.tracking.click,
                },
                on: {
                  change: [
                    function ($event) {
                      var $$a = _vm.org.tracking.click,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 &&
                            _vm.$set(
                              _vm.org.tracking,
                              "click",
                              $$a.concat([$$v])
                            )
                        } else {
                          $$i > -1 &&
                            _vm.$set(
                              _vm.org.tracking,
                              "click",
                              $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                            )
                        }
                      } else {
                        _vm.$set(_vm.org.tracking, "click", $$c)
                      }
                    },
                    _vm.updateTracking,
                  ],
                },
              }),
              _vm._v(
                " Track when links within billing emails are clicked by customers"
              ),
            ]),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "mt4" }, [
      _c("h3", { staticClass: "asc ma0" }, [_vm._v("Signature (Pro)")]),
      _vm._v(" "),
      !_vm.org.billing || !_vm.org.billing.pro
        ? _c("div", [
            _c(
              "small",
              [
                _c("router-link", { attrs: { to: "/settings/billing" } }, [
                  _vm._v("Upgrade to Pro"),
                ]),
                _vm._v(" to disable signature"),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "pv1" }, [
        _c(
          "label",
          {
            staticClass: "fade db pv1",
            style: _vm.org.billing && _vm.org.billing.pro ? "" : "opacity:0.6",
          },
          [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.org.unsigned,
                  expression: "org.unsigned",
                },
              ],
              attrs: {
                type: "checkbox",
                disabled: !_vm.org.billing || !_vm.org.billing.pro,
              },
              domProps: {
                checked: Array.isArray(_vm.org.unsigned)
                  ? _vm._i(_vm.org.unsigned, null) > -1
                  : _vm.org.unsigned,
              },
              on: {
                change: [
                  function ($event) {
                    var $$a = _vm.org.unsigned,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 &&
                          _vm.$set(_vm.org, "unsigned", $$a.concat([$$v]))
                      } else {
                        $$i > -1 &&
                          _vm.$set(
                            _vm.org,
                            "unsigned",
                            $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                          )
                      }
                    } else {
                      _vm.$set(_vm.org, "unsigned", $$c)
                    }
                  },
                  _vm.updateBranding,
                ],
              },
            }),
            _vm._v(' Remove "sent from Plover" email signature'),
          ]
        ),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "mt4" }, [
      _c("h3", { staticClass: "asc ma0" }, [_vm._v("Custom domain (Pro)")]),
      _vm._v(" "),
      !_vm.org.billing || !_vm.org.billing.pro
        ? _c("div", [
            _c(
              "small",
              [
                _c("router-link", { attrs: { to: "/settings/billing" } }, [
                  _vm._v("Upgrade to Pro"),
                ]),
                _vm._v(" to send from your own domain"),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.org.domain.dns && _vm.org.domain.verified
        ? _c("div", [
            _c("small", { staticClass: "fade" }, [
              _vm._v(
                'You have been verified. Please update your "From" email above'
              ),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "form",
        {
          attrs: { method: "post" },
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.setupDomain.apply(null, arguments)
            },
          },
        },
        [
          _c("div", { staticClass: "pv1" }, [
            _c(
              "label",
              {
                staticClass: "db fade pv1",
                style:
                  _vm.org.billing && _vm.org.billing.pro ? "" : "opacity:0.6",
              },
              [_vm._v("Domain name")]
            ),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.org.domain.name,
                  expression: "org.domain.name",
                },
              ],
              attrs: {
                type: "text",
                disabled: !_vm.org.billing || !_vm.org.billing.pro,
              },
              domProps: { value: _vm.org.domain.name },
              on: {
                input: function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.org.domain, "name", $event.target.value)
                },
              },
            }),
            _vm._v(" "),
            !_vm.org.domain.dns
              ? _c(
                  "button",
                  {
                    style:
                      _vm.org.billing && _vm.org.billing.pro
                        ? ""
                        : "opacity:0.6",
                    attrs: {
                      type: "submit",
                      disabled: _vm.working || !_vm.org.billing,
                    },
                  },
                  [_vm._v(_vm._s(_vm.working ? "Updating..." : "Update"))]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.org.domain.dns && !_vm.org.domain.verified
              ? _c(
                  "button",
                  {
                    staticClass: "button-default",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        _vm.verifyModal = true
                      },
                    },
                  },
                  [_vm._v("Verify")]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.org.domain.dns
              ? _c(
                  "button",
                  {
                    staticClass: "button-del",
                    attrs: { type: "button" },
                    on: { click: _vm.removeDomain },
                  },
                  [_vm._v("Remove")]
                )
              : _vm._e(),
          ]),
        ]
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "mt4" }, [
      _c("h3", { staticClass: "asc ma0 pb2" }, [_vm._v("Danger zone")]),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.showConfirm,
              expression: "!showConfirm",
            },
          ],
          staticClass: "pt2",
        },
        [
          _c(
            "button",
            { staticClass: "button-del", on: { click: _vm.toggleConfirm } },
            [_vm._v("Delete account")]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "form",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showConfirm,
              expression: "showConfirm",
            },
          ],
          attrs: { method: "post" },
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.deleteAccount.apply(null, arguments)
            },
          },
        },
        [
          _c("div", { staticClass: "pt2 measure" }, [
            _vm._v(
              "Are you sure you want to delete this account? Your event emails will no longer work and all related data to this account will be removed from our service."
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "pt2" }, [
            _c(
              "button",
              { staticClass: "button-del", attrs: { type: "submit" } },
              [_vm._v("Yes, delete account")]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "button button-plain",
                attrs: { type: "button" },
                on: { click: _vm.toggleConfirm },
              },
              [_vm._v("Cancel")]
            ),
          ]),
        ]
      ),
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.verifyModal,
            expression: "verifyModal",
          },
        ],
        staticClass: "modal",
        attrs: { tabindex: "0" },
        on: {
          keyup: function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
            ) {
              return null
            }
            _vm.verifyModal = false
          },
        },
      },
      [
        _c("div", { staticClass: "w-40-ns w-50 center modal-body mt4 pa4" }, [
          _vm.info
            ? _c("div", { staticClass: "pv2 info" }, [_vm._v(_vm._s(_vm.info))])
            : _vm._e(),
          _vm._v(" "),
          _vm.error
            ? _c("div", { staticClass: "pv2 error" }, [
                _vm._v(_vm._s(_vm.error)),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "mb3" }, [
            _vm._v(
              "Update your domain's DNS with the following values. Wait for some time for the values to propagate and click the verify button."
            ),
          ]),
          _vm._v(" "),
          _c(
            "table",
            { staticClass: "w-100" },
            [
              _vm._m(0),
              _vm._v(" "),
              _vm._l(_vm.org.domain.dns, function (dns, index) {
                return _c("tr", { key: index }, [
                  _c(
                    "td",
                    {
                      staticClass: "pa2 fade code f6",
                      attrs: { valign: "top" },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "flex" },
                        [
                          dns.valid == "valid"
                            ? _c("check-icon", {
                                staticClass: "pr2",
                                staticStyle: { color: "#390" },
                              })
                            : _vm._e(),
                          _vm._v(_vm._s(dns.record_type)),
                        ],
                        1
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "td",
                    {
                      staticClass: "pa2 fade code f6",
                      attrs: { valign: "top" },
                    },
                    [_vm._v(_vm._s(dns.priority ? dns.priority : dns.name))]
                  ),
                  _vm._v(" "),
                  _c(
                    "td",
                    {
                      staticClass: "pa2 fade code f6",
                      attrs: { valign: "top" },
                    },
                    [
                      _c(
                        "div",
                        { staticStyle: { "word-break": "break-word" } },
                        [_vm._v(_vm._s(dns.value))]
                      ),
                    ]
                  ),
                ])
              }),
            ],
            2
          ),
          _vm._v(" "),
          _c("div", { staticClass: "mt3" }, [
            _c(
              "button",
              {
                staticClass: "button-default",
                staticStyle: { "margin-bottom": "0" },
                attrs: { disabled: _vm.working },
                on: { click: _vm.verifyDomain },
              },
              [_vm._v(_vm._s(_vm.working ? "Verifying..." : "Verify"))]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "button-plain",
                staticStyle: { "margin-bottom": "0" },
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    _vm.verifyModal = false
                  },
                },
              },
              [_vm._v("Cancel")]
            ),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("th", { staticClass: "pa2 tl" }, [_vm._v("Type")]),
      _vm._v(" "),
      _c("th", { staticClass: "pa2 tl" }, [_vm._v("Name/Priority")]),
      _vm._v(" "),
      _c("th", { staticClass: "pa2 tl" }, [_vm._v("Value")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }