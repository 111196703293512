var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "mid fixed w-80-l w-90 h-100",
        staticStyle: { "border-right": "0" },
      },
      [
        _c("div", { staticClass: "w-70 center pv5" }, [
          _c("ul", { staticClass: "line-menu" }, [
            _c(
              "li",
              [
                _c(
                  "router-link",
                  {
                    staticClass: "pa2",
                    attrs: {
                      to: "/settings/profile",
                      "active-class": "active",
                    },
                  },
                  [_vm._v("Profile")]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "li",
              [
                _vm.isAdmin
                  ? _c(
                      "router-link",
                      {
                        staticClass: "pa2",
                        attrs: {
                          to: "/settings/org",
                          "active-class": "active",
                        },
                      },
                      [_vm._v("Account")]
                    )
                  : _vm._e(),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "li",
              [
                _c(
                  "router-link",
                  {
                    staticClass: "pa2",
                    attrs: { to: "/settings/team", "active-class": "active" },
                  },
                  [_vm._v("Team")]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "li",
              [
                _vm.isAdmin
                  ? _c(
                      "router-link",
                      {
                        staticClass: "pa2",
                        attrs: {
                          to: "/settings/billing",
                          "active-class": "active",
                        },
                      },
                      [_vm._v("Billing")]
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "pv4" }, [_c("router-view")], 1),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }