var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "mid fixed w-90 w-80-l h-100",
        staticStyle: { "border-right": "0", "background-color": "#f5f6fa" },
      },
      [
        _c("div", { staticClass: "w-90 center pv5" }, [
          _vm.error
            ? _c("div", { staticClass: "error pb3" }, [
                _vm._v(_vm._s(_vm.error)),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "pa2 fr" }, [
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.showmonth,
                    expression: "showmonth",
                  },
                ],
              },
              [_vm._v("This month")]
            ),
            _c(
              "a",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.showmonth,
                    expression: "!showmonth",
                  },
                ],
                staticClass: "fade",
                attrs: { href: "#" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    _vm.showmonth = true
                  },
                },
              },
              [_vm._v("This Month")]
            ),
            _vm._v(" "),
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.showmonth,
                    expression: "!showmonth",
                  },
                ],
                staticClass: "ml2 dib",
              },
              [_vm._v("All time")]
            ),
            _c(
              "a",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.showmonth,
                    expression: "showmonth",
                  },
                ],
                staticClass: "ml2 fade",
                attrs: { href: "#" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    _vm.showmonth = false
                  },
                },
              },
              [_vm._v("All time")]
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "pa2 b" }, [_vm._v(" ")]),
          _vm._v(" "),
          _vm._m(0),
          _vm._v(" "),
          _vm._m(1),
          _vm._v(" "),
          _c("h3", [_vm._v("Payable")]),
          _vm._v(" "),
          _c(
            "table",
            { staticClass: "mt4 w-100" },
            [
              _vm._m(2),
              _vm._v(" "),
              _vm._l(_vm.payables, function (transaction) {
                return _c("tr", { key: transaction._id }, [
                  _c(
                    "td",
                    [
                      _c(
                        "router-link",
                        { attrs: { to: "/entities/" + transaction._id } },
                        [_vm._v(_vm._s(transaction.name))]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("td", [
                    _vm._v(
                      _vm._s(
                        transaction.payable_amount
                          ? transaction.payable_amount.toLocaleString()
                          : 0
                      )
                    ),
                  ]),
                  _vm._v(" "),
                  _c("td", [
                    _vm._v(
                      _vm._s(
                        transaction.payable_balance
                          ? transaction.payable_balance.toLocaleString()
                          : 0
                      )
                    ),
                  ]),
                ])
              }),
            ],
            2
          ),
          _vm._v(" "),
          _c("h3", [_vm._v("Receivable")]),
          _vm._v(" "),
          _c(
            "table",
            { staticClass: "mt4 w-100" },
            [
              _vm._m(3),
              _vm._v(" "),
              _vm._l(_vm.receivables, function (transaction) {
                return _c("tr", { key: transaction._id }, [
                  _c(
                    "td",
                    [
                      _c(
                        "router-link",
                        { attrs: { to: "/entities/" + transaction._id } },
                        [_vm._v(_vm._s(transaction.name))]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("td", [
                    _vm._v(
                      _vm._s(
                        transaction.receivable_amount
                          ? transaction.receivable_amount.toLocaleString()
                          : 0
                      )
                    ),
                  ]),
                  _vm._v(" "),
                  _c("td", [
                    _vm._v(
                      _vm._s(
                        transaction.receivable_balance
                          ? transaction.receivable_balance.toLocaleString()
                          : 0
                      )
                    ),
                  ]),
                ])
              }),
            ],
            2
          ),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("div", { staticClass: "fl w-50 tc pa2" }, [
        _c("div", { staticClass: "box ph3 pt3 pb4" }, [
          _c("div", [_vm._v("Outstanding")]),
          _vm._v(" "),
          _c("div", { staticClass: "mb2 flex justify-between" }, [
            _c("div", { staticClass: "tc w-50" }, [
              _c("h2", [_vm._v("1")]),
              _vm._v(" "),
              _c("div", [_vm._v("Payable")]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "tc w-50" }, [
              _c("h2", [_vm._v("2")]),
              _vm._v(" "),
              _c("div", [_vm._v("Receivable")]),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "fl w-50 tc pa2" }, [
        _c("div", { staticClass: "box ph3 pt3 pb4" }, [
          _c("div", [_vm._v("Past Due")]),
          _vm._v(" "),
          _c("div", { staticClass: "mb2 flex justify-between" }, [
            _c("div", { staticClass: "tc w-50" }, [
              _c("h2", [_vm._v("1")]),
              _vm._v(" "),
              _c("div", [_vm._v("Payable")]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "tc w-50" }, [
              _c("h2", [_vm._v("2")]),
              _vm._v(" "),
              _c("div", [_vm._v("Receivable")]),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "cb" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("div", { staticClass: "fl w-50 tc pa2" }, [
        _c("div", { staticClass: "box ph3 pt3 pb4" }, [
          _c("div", [_vm._v("Due soon")]),
          _vm._v(" "),
          _c("div", { staticClass: "mb2 flex justify-between" }, [
            _c("div", { staticClass: "tc w-50" }, [
              _c("h2", [_vm._v("1")]),
              _vm._v(" "),
              _c("div", [_vm._v("Payable")]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "tc w-50" }, [
              _c("h2", [_vm._v("2")]),
              _vm._v(" "),
              _c("div", [_vm._v("Receivable")]),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "fl w-50 tc pa2" }, [
        _c("div", { staticClass: "box ph3 pt3 pb4" }, [
          _c("div", [_vm._v("Average day")]),
          _vm._v(" "),
          _c("div", { staticClass: "mb2 flex justify-between" }, [
            _c("div", { staticClass: "tc w-50" }, [
              _c("h2", [_vm._v("1")]),
              _vm._v(" "),
              _c("div", [_vm._v("Payable")]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "tc w-50" }, [
              _c("h2", [_vm._v("2")]),
              _vm._v(" "),
              _c("div", [_vm._v("Receivable")]),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "cb" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Customer name")]),
        _vm._v(" "),
        _c("th", [_vm._v("Total amount")]),
        _vm._v(" "),
        _c("th", [_vm._v("Balance due")]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Customer name")]),
        _vm._v(" "),
        _c("th", [_vm._v("Total amount")]),
        _vm._v(" "),
        _c("th", [_vm._v("Balance due")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }