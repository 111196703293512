var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.user
      ? _c("div", [
          _c("h3", { staticClass: "asc ma0 pb2" }, [_vm._v("Profile info")]),
          _vm._v(" "),
          _vm.info
            ? _c("div", { staticClass: "pv2 info" }, [_vm._v(_vm._s(_vm.info))])
            : _vm._e(),
          _vm._v(" "),
          _vm.error
            ? _c("div", { staticClass: "pv2 error" }, [
                _vm._v(_vm._s(_vm.error)),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "form",
            {
              attrs: { method: "post" },
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.updateName.apply(null, arguments)
                },
              },
            },
            [
              _c("div", { staticClass: "pv1" }, [
                _c("label", { staticClass: "db fade pv1" }, [_vm._v("Name")]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.user.name,
                      expression: "user.name",
                    },
                  ],
                  staticClass: "w-100",
                  attrs: { type: "text" },
                  domProps: { value: _vm.user.name },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.user, "name", $event.target.value)
                    },
                  },
                }),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "pt2" }, [
                _c(
                  "button",
                  {
                    staticClass: "w-100",
                    attrs: { type: "submit", disabled: _vm.submitName },
                  },
                  [_vm._v(_vm._s(_vm.submitName ? "Updating..." : "Update"))]
                ),
              ]),
            ]
          ),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "mt4" }, [
      _c("h3", { staticClass: "asc ma0 pb2" }, [_vm._v("Password")]),
      _vm._v(" "),
      _c(
        "form",
        {
          attrs: { method: "post" },
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.updatePassword.apply(null, arguments)
            },
          },
        },
        [
          _c("div", { staticClass: "pv1" }, [
            _c("label", { staticClass: "db fade pv1" }, [
              _vm._v("Current password"),
            ]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.oldPassword,
                  expression: "oldPassword",
                },
              ],
              staticClass: "w-100",
              attrs: { type: "password" },
              domProps: { value: _vm.oldPassword },
              on: {
                input: function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.oldPassword = $event.target.value
                },
              },
            }),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "pv1" }, [
            _c("label", { staticClass: "db fade pv1" }, [
              _vm._v("New password"),
            ]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.password,
                  expression: "password",
                },
              ],
              staticClass: "w-100",
              attrs: { type: "password" },
              domProps: { value: _vm.password },
              on: {
                input: function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.password = $event.target.value
                },
              },
            }),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "pt2" }, [
            _c(
              "button",
              {
                staticClass: "w-100",
                attrs: { type: "submit", disabled: _vm.submitPwd },
              },
              [_vm._v(_vm._s(_vm.submitPwd ? "Updating..." : "Update"))]
            ),
          ]),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }