var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "mid fixed w-90 w-80-l h-100",
        staticStyle: { "border-right": "0", "background-color": "#f5f6fa" },
      },
      [
        _c("div", { staticClass: "w-90 center pv5" }, [
          _vm.error
            ? _c("div", { staticClass: "error pb3" }, [
                _vm._v(_vm._s(_vm.error)),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("table", { staticClass: "mt4 w-100" }, [
            _vm._m(0),
            _vm._v(" "),
            _c(
              "tbody",
              _vm._l(_vm.entities.data, function (entity) {
                return _c("tr", { key: entity._id }, [
                  _c(
                    "td",
                    [
                      _c(
                        "router-link",
                        { attrs: { to: "/entities/" + entity._id } },
                        [_vm._v(_vm._s(entity.name))]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("td", [
                    _vm._v(_vm._s(entity.roles ? entity.roles.join(", ") : "")),
                  ]),
                ])
              }),
              0
            ),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", { staticClass: "tl" }, [_vm._v("Name")]),
        _vm._v(" "),
        _c("th", { staticClass: "tl" }, [_vm._v("Roles")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }