<template>
  <div>
    <div class="mid fixed w-90 w-80-l h-100" style="border-right:0;background-color:#f5f6fa">
      <div class="w-90 center pv5">
        <div class="error pb3" v-if="error">{{ error }}</div>
        
        <table class="mt4 w-100">
          <thead>
            <tr>
              <th class="tl">Name</th>
              <th class="tl">Roles</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="entity of entities.data" :key="entity._id">
              <td><router-link :to="'/entities/'+entity._id">{{entity.name}}</router-link></td>
              <td>{{entity.roles ? entity.roles.join(', ') : ''}}</td>
            </tr>
          </tbody>
        </table>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      error: '',
      loading: true,
      entities: {
        data: []
      }
    }
  },

  mounted: async function () {
    // Load all entities
    try {
      this.error = ''
      const response = await fetch('/api/entities', {
        headers: { 'Content-Type': 'application/json;charset=utf-8' }
      })
      let data = {}
      try {
        data = await response.json()
      } catch (j) {}
      if (response.status !== 200 || !data.data || !Array.isArray(data.data)) {
        this.error = data.error ? data.error : 'There has been a connection error. Try again later.'
        return;
      }
      this.entities.data = data.data
    } catch (e) {
      this.error = e.message
    }
  },
}
</script>

<style scoped>
</style>