import Vue from 'vue'
import Vuex from 'vuex'
import VueRouter from 'vue-router'

// import Init from '../components/Init.vue'
import Sidebar from '../components/Sidebar.vue'
import Dashboard from '../components/Dashboard.vue'
import Inventory from '../components/Inventory.vue'
import Transactions from '../components/Transactions.vue'
import TransactionNew from '../components/TransactionNew.vue'
import Entities from '../components/Entities.vue'
import Entity from '../components/Entity.vue'
import Product from '../components/Product.vue'
import PR from '../components/PR.vue'
import Settings from '../components/Settings.vue'
import ProfileSettings from '../components/SettingsProfile.vue'
import OrgSettings from '../components/SettingsOrg.vue'
import Billing from '../components/SettingsBilling.vue'
import Team from '../components/SettingsTeam.vue'

import VueTimeago from 'vue-timeago'

Vue.use(VueRouter)
Vue.use(Vuex)
Vue.use(VueTimeago, {
  name: 'Timeago'
})

const routes = [
  {
    path: '/',
    name: 'dashboard',
    component: Sidebar,
    children: [{
      path: '',
      component: Dashboard
    }, {
      path: '/entities',
      component: Entities
    }, {
      path: '/entities/:id',
      component: Entity
    }, {
      path: '/pnr',
      component: PR
    }, {
      path: '/inventory',
      component: Inventory
    }, {
      path: '/inventory/:id',
      component: Product
    }, {
      path: '/transactions',
      component: Transactions
    }, {
      path: '/transactions/new',
      component: TransactionNew
    }, {
      path: '/settings',
      component: Settings,
      children: [{
        path: '/settings/profile',
        component: ProfileSettings
      }, {
        path: '/settings/org',
        component: OrgSettings
      }, {
        path: '/settings/team',
        component: Team
      }, {
        path: '/settings/billing',
        component: Billing
      }]
    }]
  }
  // { path: '/connect', name: 'connect', component: Connect },
  // { path: '/connect/email/sharedbox', name: 'connect-sharedbox', component: ConnectSharedbox }
]
const router = new VueRouter({
  // mode: 'history',
  routes
})

const store = new Vuex.Store({
  state: {
    org: {}
  },
  mutations: {
    setOrg (state, org) {
      state.org = org
    },
    updateOrgDomain (state, domain) {
      state.org.domain = domain
    },
    updateOrgName (state, name) {
      state.org.name = name
    },
    addMember (state, member) {
      state.org.members.push(member)
    },
    updateUser (state, user) {
      if (user.name) {
        state.org.user.name = user.name
      }
      if (user.email) {
        state.org.user.email = user.email
      }
    },
    removeMember (state, email) {
      for (let i = 0; i < state.org.members.length; i++) {
        if (state.org.members[i].email === email) {
          state.org.members.splice(i, 1)
          return
        }
      }
    },
    toggleRole (state, email) {
      state.org.members = state.org.members.map(member => {
        if (member.email === email) {
          member.role = (member.role === 'admin') ? 'member' : 'admin'
        }
        return member
      })
    },

    addCard (state, card) {
      if (!Array.isArray(state.org.cards)) {
        state.org.cards = []
      }
      state.org.cards.push(card)
    },
    setDefaultCard (state, id) {
      state.org.cards = state.org.cards.map(card => {
        if (card.id === id) {
          card.default = true
        }
        if (card.id !== id && card.default) {
          delete card.default
        }
        return card
      })
    },
    removeCard (state, id, def) {
      // Set new default card and delete removed card
      // Chaining doesnt "react". So had to separate
      state.org.cards = state.org.cards.map(card => {
        if (card.id === def) {
          card.default = true
        }
        return card
      })
      state.org.cards = state.org.cards.filter(card => {
        return !(card.id === id)
      })
    },
    upgrade (state) {
      state.org.billing.pro = true
      state.org.billing.unsigned = true
    },
    downgrade (state) {
      delete state.org.billing.pro
      delete state.org.unsigned
    }
  }
})

// App
new Vue({
  el: '#app',
  store,
  router,
  template: '<router-view></router-view>'
})
